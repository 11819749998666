import React, { useEffect, useRef, useState } from "react";
import { useInView } from "framer-motion";
import "animate.css";
import "./ourTechnology.scss";
import slide2 from "assets/images/slider-2.png";
import slide1 from "assets/images/slider-1.png";
import slide3 from "assets/images/slider-3.png";

const OurTechnology = () => {
  const [imageInView, setImageInView] = useState(0);
  const ourTechnology = useRef();
  const ourTechnologyInView = useInView(ourTechnology, {
    amount: 0.1,
    once: true
  });
  const ourTechnologyTabsInView = useInView(ourTechnology, {
    amount: 0.2,
    once: true
  });

  const TextInView = ({ children, className, item }) => {
    const textContainer = useRef(null);

    useEffect(() => {
      const handleScroll = () => {
        if (
          textContainer?.current?.getBoundingClientRect().top > -200 &&
          textContainer?.current?.getBoundingClientRect().top < 400
        ) {
          setImageInView(item);
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
      <div ref={textContainer} className={className}>
        <span>{children}</span>
      </div>
    );
  };

  return (
    <div className="ourTechnology" ref={ourTechnology}>
      <div className="ourTechnologyHeading">
        <h4
          className={
            ourTechnologyInView
              ? "animate__animated animate__fadeInUp"
              : "animate__animated animate__fadeOutDown"
          }
        >
          OUR TECHNOLOGY
        </h4>
        <h3
          className={
            ourTechnologyInView
              ? "animate__animated animate__fadeInUp"
              : "animate__animated animate__fadeOutDown"
          }
        >
          Scalable 4D-MRV across all nature based solutions
        </h3>
      </div>

      <div
        className={`ourTechnologyContent ${
          ourTechnologyTabsInView
            ? "animate__animated animate__fadeInUp"
            : "animate__animated animate__fadeOutDown"
        }`}
      >
        <div className={`textContainer`}>
          <div className="textImage">
            <img src={slide1} alt="" />
          </div>
          <TextInView className="textItem" item={0}>
            <h3>Monitoring the world via a 10㎡ Terragrid</h3>
            <p>
              We directly monitor historic and near real-time soil carbon and
              land management activities at a granular level.
            </p>
          </TextInView>
          <div className="textImage">
            <img src={slide2} alt="" />
          </div>
          <TextInView className="textItem" item={1}>
            <h3>Modelling the data</h3>
            <p>
              We quantify at the finest detail; carbon stock, carbon
              sequestration & GHG emissions along with their spatial and
              temporal distribution.
            </p>
          </TextInView>
          <div className="textImage">
            <img src={slide3} alt="" />
          </div>
          <TextInView className="textItem" item={2}>
            <h3>Digital Twin</h3>
            <p>
              Through the assimilation of EO data, our process models are
              spatialised over large areas to provide robust and transparent
              outputs.
            </p>
          </TextInView>
        </div>
        <div className="imageContainer">
          <div
            className="rotateBox"
            style={{
              transform: imageInView === 1 ? "rotateY(180deg)" : "rotateY(0deg)"
            }}
          >
            <div
              className="step-01"
              style={{ opacity: imageInView === 0 ? "100%" : "0%" }}
            >
              <img src={slide1} alt="" />
            </div>
            <div
              className="step-02"
              style={{ opacity: imageInView === 1 ? "100%" : "0%" }}
            >
              <img src={slide2} alt="" />
            </div>
            <div
              className="step-03"
              style={{ opacity: imageInView === 2 ? "100%" : "0%" }}
            >
              <img src={slide3} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTechnology;
