import React, { useState, useEffect } from "react";

const Clock = ({ timezone }) => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const hours = currentTime.toLocaleString("en-GB", {
    timeZone: timezone,
    hour: "numeric"
  });

  const minutes = currentTime.toLocaleString("en-GB", {
    timeZone: timezone,
    minute: "numeric"
  });

  const minutesStyle = {
    transform: `rotate(${minutes * 6}deg)`
  };

  const hoursStyle = {
    transform: `rotate(${hours * 30}deg)`
  };

  return (
    <div className="contactClock">
      <div className="contactClockMinutes dial" style={minutesStyle} />
      <div className="contactClockHours dial" style={hoursStyle} />
    </div>
  );
};

export default Clock;

// import React, { useEffect, useState } from "react";

// const Clock = () => {
//   const [hours, setHours] = useState(new Date().getHours());
//   const [minutes, setMinutes] = useState(new Date().getSeconds());
//   const [minutesStyle, setMinutesStyle] = useState({
//     tranform: `rotate(${minutes * 6} deg)`
//   });
//   const [hoursStyle, setHoursStyle] = useState({
//     tranform: `rotate(${minutes * 30} deg)`
//   });

//   setInterval(() => {
//     setMinutes(new Date().getSeconds());
//     setHours(new Date().getHours());
//   }, 1000);

//   useEffect(() => {
//     setMinutesStyle({
//       tranform: `rotate(${minutes * 6} deg)`
//     });
//     setHoursStyle({
//       tranform: `rotate(${minutes * 30} deg)`
//     });
//   }, [minutes, hours]);

//   console.log(minutesStyle);

//   return (
//     <div className="contactClock">
//       <div className="contactClockMinutes dial" style={minutesStyle} />
//       <div className="contactClockHours dial" style={hoursStyle} />
//     </div>
//   );
// };

// export default Clock;
