import { motion } from "framer-motion";

import "./productFeatures.scss";

const ProductFeatures = () => {
  return (
    <div className="productFeatures" id="productFeatures">
      <div className="productFeaturesLeft">
        <h4>PRODUCT FEATURES</h4>
        <h2>Geotree brings together...</h2>
      </div>

      <div className="productFeaturesRight">
        <motion.div
          initial={{ opacity: 0, x: -200 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.3, duration: 0.5 }}
          className="textItem"
        >
          <h3>Early stage project screening and feasibility</h3>
          <p>
            granular analysis of historical land use, carbon potential and
            non-permanence risk.
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: -200 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.3, duration: 0.5 }}
          className="textItem"
        >
          <h3>Real-time verification</h3>
          <p>
            climate impact, potential leakage, permanence and additionality of
            environmental interventions.
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: -200 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.3, duration: 0.5 }}
          className="textItem"
        >
          <h3>Deep execution and carbon market experience</h3>
          <p>
            working with project proponents to develop projects and provide
            carbon credit analysis to customers.
          </p>
        </motion.div>
      </div>
    </div>
  );
};

export default ProductFeatures;
