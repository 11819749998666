import "animate.css";

import { contactLocations } from "constant/contactInTouch";

import "components/ContactInTouch/contactInTouch.scss";
import Clock from "./Clock";

const index = () => {
  return (
    <>
      <div className="contactUs">
        <section className="contact">
          <div className="container">
            <div className="contactHeading">
              <h2 className="animate__animated animate__fadeInUp">
                Get in touch
              </h2>
              <h4 className="animate__animated animate__fadeInUp">
                INFO@MANTLE-LABS.COM
              </h4>
              <p className="animate__animated animate__fadeInUp">
                Learn how we can work together to unlock carbon removal at a
                gigaton scale through science-backed technology.
              </p>
            </div>

            <div className="contactLocations animate__animated animate__fadeInUp">
              {contactLocations.map((location, index) => (
                <div className="contactContents" key={index}>
                  <Clock timezone={location?.timezone} />
                  <h4>{location.location}</h4>
                  <p>{location.address}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default index;
