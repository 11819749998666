import InterestedPartner from "components/InterestedPartner/index";
import Mission from "components/Mission/index";
import OurMissions from "components/OurMissions/index";
// import OurTeam from "components/OurTeam/index";
import ManagementTeam from "components/ManagementTeam/index";
import Partnership from "components/Partnership/index";

import "containers/Aboutus/aboutus.scss";

const Aboutus = () => {
  return (
    <div className="aboutUs">
      <OurMissions />
      {/* <OurTeam /> */}
      <Mission />
      <ManagementTeam />
      <Partnership />
      <InterestedPartner />
    </div>
  );
};

export default Aboutus;
