import "components/InsightCard/insightCard.scss";

const InsightCard = ({ data, action }) => {
  return (
    <div className="insightCard">
      <div className="insightCardImg">
        <img src={data.image} alt="" />
      </div>
      <div className="insightCardContent">
        <span htmlFor="">Research</span>
        <p>{data.description}</p>
        <button onClick={() => action(data?.id)}>
          <img src={data?.buttonIcon} alt="" />
          {data?.buttonText}
        </button>
      </div>
    </div>
  );
};

export default InsightCard;
