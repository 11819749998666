export const contactLocations = [
  {
    location: "Mantle Labs HQ",
    address: "29 Farm Street, London W1J 5RL",
    timezone: "Europe/London"
  },
  {
    location: "Mantle Labs Austria",
    address: "Grünentorgasse, 19/41090, Vienna",
    timezone: "Europe/Vienna"
  }
];
