import { Link } from "react-router-dom";
import { newsBlogData } from "constant/newsData";
import "components/NewsBlog/newsBlog.scss";

const NewsBlog = () => {
  return (
    <section className="newsBlog">
      <div className="container">
        <h3>All News</h3>
        <div className="newsBlogContainer">
          {newsBlogData.map((data, index) => (
            <div className="newsBlogSection" key={index}>
              <div className="newsBlogSectionImg">
                <img src={data.image} alt="NewsImg" />
              </div>
              <span>{data.title}</span>
              <h4>{data.heading}</h4>
              <p>{data.para}</p>
              <Link to={data.link} target="_blank">
                <div className="newsBlogSectionButton">
                  <button>{data.button}</button>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default NewsBlog;
