import { useRef } from "react";
import { useInView } from "framer-motion";
import "animate.css";

import MissionImg from "assets/images/about-us/missionImg.png";

import "components/Mission/mission.scss";

const Mission = () => {
  const mission = useRef();
  const missionInView = useInView(mission, { amount: 0.1, once: true });
  const missionContentInView = useInView(mission, { amount: 0.2, once: true });
  return (
    <section className="mission" ref={mission}>
      <div className="container">
        <div className="missionHeading">
          <h3
            className={
              missionInView
                ? "animate__animated animate__fadeInUp"
                : "animate__animated animate__fadeOutUp"
            }
          >
            OUR MISSION
          </h3>
          <h2
            className={
              missionInView
                ? "animate__animated animate__fadeInUp"
                : "animate__animated animate__fadeOutUp"
            }
          >
            The true secret of our success lies in our people
          </h2>
        </div>
        <div
          className={`missionContent ${
            missionContentInView
              ? "animate__animated animate__fadeInUp"
              : "animate__animated animate__fadeOutBottom"
          }`}
        >
          <div className="missionContentDetails">
            <p>
              At Mantle Labs, our mission is to provide our clients with the
              most accurate and comprehensive analysis for climate mitigation
              and resilience in agriculture and forestry, using remote sensing
              data, environmental science and AI. Our team has industry-leading
              expertise across all of these fields.
            </p>
            <p>
              We believe that by offering ground-breaking solutions that can
              help businesses, governments and communities make informed
              decisions towards a better future, we can make a positive impact
              on the world. We are committed to driving the latest developments
              in our field and to continually improving our services to ensure
              the highest level of quality and impact for our clients.
            </p>
            <p>
              We are proud to be at the forefront of the global effort to combat
              climate change and create a more sustainable world.
            </p>
          </div>
          <div className="missionContentImg">
            <img src={MissionImg} alt="MissionImg" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Mission;
