import React, { useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";

import CarouselSlide from "components/CarbonCarousel/CarouselSlide";

import slide1 from "assets/images/home/Slide-1.png";
import slide2 from "assets/images/home/Slide-2.png";
import slide3 from "assets/images/home/Slide-3.png";
import slide4 from "assets/images/home/Slide-4.png";
import slide1Img from "assets/images/home/slide-1Mobile.png";
import slide2Img from "assets/images/home/slide-2Mobile.png";
import slide3Img from "assets/images/home/slide-3Mobile.png";
import slide4Img from "assets/images/home/slide4-Mobile.png";
import sliderLogo from "assets/images/home/slider1.svg";
import slider2Logo from "assets/images/home/slider2.svg";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "components/CarbonCarousel/carbonCarousel.scss";

const CustomCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const carouselRef = useRef(null);

  const handleOnChange = (index) => {
    setActiveIndex(index);
  };

  const handleImage = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % 4);
  };

  const handlePrevImage = () => {
    console.log((activeIndex - 1 + 4) % 4);
    const currentIndex = (activeIndex - 1 + 4) % 4;
    setActiveIndex(currentIndex);
    // onClickHandler(currentIndex);
  };

  const buttonContent = [
    "Rice Methane",
    "Managed Grazing",
    "Forestry",
    "Regenerative Agriculture"
  ];

  const isMobile = window.innerWidth <= 992;

  // Define the array of image paths based on mobile status
  const imagePaths = isMobile
    ? [slide1Img, slide2Img, slide3Img, slide4Img]
    : [slide1, slide2, slide3, slide4];

  return (
    <div className="carouselWrapper">
      <div className="carouselContent">
        <Carousel
          ref={carouselRef}
          infiniteLoop={true}
          swipeable={false}
          showThumbs={false}
          showStatus={false}
          autoPlay={true}
          stopOnHover={false}
          verticalSwipe={false}
          interval={5000}
          selectedItem={activeIndex}
          onChange={handleOnChange}
          showIndicators={true}
          animationHandler={window.innerWidth >= 992 ? "fade" : "fade"}
          showArrows={false}
          renderIndicator={(onClickHandler, isSelected, index) => {
            return (
              <>
                {isMobile ? (
                  <div className="mobile-indicators">
                    {index === activeIndex && (
                      <>
                        <div className="prev-arrow" onClick={handlePrevImage}>
                          <img src={sliderLogo} alt="slideImg" />
                        </div>
                        <button
                          onClick={onClickHandler}
                          className={`sliderButton ${
                            isSelected ? "activeBtn" : ""
                          }`}
                        >
                          {buttonContent[index]}
                        </button>
                        <div className="next-arrow" onClick={handleImage}>
                          <img src={slider2Logo} alt="slideImg" />
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <>
                    <div className="desktop-indicators">
                      <button
                        onClick={onClickHandler}
                        className={`sliderButton ${isSelected ? "active" : ""}`}
                      >
                        {buttonContent[index]}
                      </button>
                    </div>
                    <div
                      className={`progress 
                    ${index < activeIndex ? "isActiveProgress" : ""}`}
                    >
                      {isSelected && <div className="progressWrap"></div>}
                    </div>
                  </>
                )}
              </>
            );
          }}
        >
          {/* Pass the imagePath based on activeIndex */}
          <CarouselSlide
            imagePath={imagePaths[0]}
            heading="Coverage Across All"
            subHeading="Nature-based Carbon Project Types"
            activeIndex={activeIndex}
            index={0}
          />
          <CarouselSlide
            imagePath={imagePaths[1]}
            heading="Coverage Across All"
            subHeading="Nature-based Carbon Project Types"
            activeIndex={activeIndex}
            index={1}
          />
          <CarouselSlide
            imagePath={imagePaths[2]}
            heading="Coverage Across All"
            subHeading="Nature-based Carbon Project Types"
            activeIndex={activeIndex}
            index={2}
          />
          <CarouselSlide
            imagePath={imagePaths[3]}
            heading="Coverage Across All"
            subHeading="Nature-based Carbon Project Types"
            activeIndex={activeIndex}
            index={3}
          />
        </Carousel>
      </div>
    </div>
  );
};

export default CustomCarousel;
