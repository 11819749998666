import video1 from "assets/videos/video1.mp4";
import video2 from "assets/videos/video3.mp4";
import video3 from "assets/videos/video2.mp4";

export const contentsData = [
  {
    number: 1,
    image: video1,
    title: "Monitoring the world via a 10㎡ Terra-grid",
    about:
      "We directly monitor historic & near real-time soil carbon & land management activities at a granular level."
  },
  {
    number: 2,
    image: video2,
    title: "Modelling the data",
    about:
      "We quantify at the finest  detail; carbon stock, carbon sequestration & GHG emissions along with their spatial and temporal distribution."
  },
  {
    number: 3,
    image: video3,
    title: "Digital Twin",
    about:
      "Through the assimilation of EO data,  our process models are spatialised  over large areas to provide robust and transparent outputs."
  }
];
