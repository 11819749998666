import ContactInTouch from "components/ContactInTouch";
import ContactForm from "components/ContactForm";

import "./contact.scss";

const Contactus = () => {
  return (
    <>
      <ContactInTouch />
      <ContactForm />
    </>
  );
};

export default Contactus;
