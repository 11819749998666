// import the react-router-dom
import { BrowserRouter, Routes, Route } from "react-router-dom";

//import containers
import Home from "containers/Home";

// import components
import Header from "components/Header";
import Footer from "components/Footer";
import Contactus from "containers/Contactus/index";
import Insights from "containers/Insights";
import Product from "containers/Product/index";
import Aboutus from "containers/Aboutus/index";
import News from "containers/News";

// import global scss
import "styles/global.scss";
import InsightsDetails from "containers/InsightsDetails/index";
import ScrollToTop from "components/ScrollToTop/index";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route exact path={"/"} element={<Home />} />
        <Route exact path={"/contact-us"} element={<Contactus />} />
        <Route exact path={"/insights"} element={<Insights />} />
        <Route exact path={"/insights/:id"} element={<InsightsDetails />} />
        <Route exact path={"/products"} element={<Product />} />
        <Route exact path={"/about-us"} element={<Aboutus />} />
        <Route exact path={"/news"} element={<News />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

// function FooterConditionally() {
//   const location = useLocation();
//   const excludedPaths = ["/"];

//   if (excludedPaths.includes(location.pathname)) {
//     return null;
//   }
//   return <Footer />;
// }

export default App;
