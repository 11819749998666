import NewsImg from "assets/images/news/newsImg.png";
import NewsImg2 from "assets/images/news/newsImg2.png";

export const newsBlogData = [
  {
    image: NewsImg,
    title: "17 NOVEMBER 2023",
    heading:
      "Verra Initiates Remote Sensing Analysis Pilot with Mantle Labs...",
    para: "Initiative sampled five projects using AMS-III.AU to check project area...",
    button: "Read More",
    link: "https://verra.org/verra-initiates-remote-sensing-analysis-pilot-with-mantle-labs-for-review-of-small-scale-methodology-rice-projects/"
  },
  {
    image: NewsImg2,
    title: "2 APRIL 2024",
    heading:
      "Japan's Faeger uses satellite tool to monitor rice methane schemes",
    para: "Quantum Commodity Intelligence – Japanese carbon project...",
    button: "Read More",
    link: "https://www.qcintel.com/carbon/article/japan-s-faeger-uses-satellite-tool-to-monitor-rice-methane-schemes-23065.html"
  }
  // {
  //   image: NewsImg,
  //   title: "17 NOVEMBER 2023",
  //   heading:
  //     "Verra Initiates Remote Sensing Analysis Pilot with Mantle Labs...",
  //   para: "Initiative sampled five projects using AMS-III.AU to check project area...",
  //   button: "Read More",
  //   link: ""
  // },
  // {
  //   image: NewsImg,
  //   title: "17 NOVEMBER 2023",
  //   heading:
  //     "Verra Initiates Remote Sensing Analysis Pilot with Mantle Labs...",
  //   para: "Initiative sampled five projects using AMS-III.AU to check project area...",
  //   button: "Read More",
  //   link: ""
  // },
  // {
  //   image: NewsImg,
  //   title: "17 NOVEMBER 2023",
  //   heading:
  //     "Verra Initiates Remote Sensing Analysis Pilot with Mantle Labs...",
  //   para: "Initiative sampled five projects using AMS-III.AU to check project area...",
  //   button: "Read More",
  //   link: ""
  // },
  // {
  //   image: NewsImg,
  //   title: "17 NOVEMBER 2023",
  //   heading:
  //     "Verra Initiates Remote Sensing Analysis Pilot with Mantle Labs...",
  //   para: "Initiative sampled five projects using AMS-III.AU to check project area...",
  //   button: "Read More",
  //   link: ""
  // }
];
