import { useState, useRef } from "react";
import { useInView } from "framer-motion";

import InsightVideoCard from "components/InsightVideoCard/index";

import "./insightsVideos.scss";
import VideoModal from "components/VideoModal/index";

import videoIimages from "assets/images/video.png";
import playButton from "assets/icons/playbutton.svg";

import "components/InsightsVideos/insightsVideos.scss";

const InsightsVideos = ({ videoRef }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const insightsVideos = useRef();
  const insightsVideosInView = useInView(insightsVideos, {
    amount: 0.1,
    once: true
  });
  // const insightsVideosCardInView = useInView(insightsVideos, {
  //   amount: 0.2,
  //   once: true
  // });

  const articleVideos = [
    {
      id: 1,
      title: "",
      subtitle: "",
      description: "Geotree: The Future of Carbon Project Analytics",
      image: videoIimages,
      buttonText: "Watch Now",
      buttonIcon: playButton,
      videoUrl: "https://www.youtube.com/embed/-68X-FBglek",
      idName: "video1"
    },
    {
      id: 2,
      title: "",
      subtitle: "",
      description:
        "Rice Methane: An Interview with Dr Khadiza Begum and Dr Belen Marti-Cardona",
      image: videoIimages,
      buttonText: "Watch Now",
      buttonIcon: playButton,
      videoUrl: "https://www.youtube.com/embed/BEOnaFQLKmw",
      idName: ""
    },
    {
      id: 3,
      title: "",
      subtitle: "",
      description:
        "Rice production and methane: Geotree and the Bangladesh Agricultural University",
      image: videoIimages,
      buttonText: "Watch Now",
      buttonIcon: playButton,
      videoUrl: "https://www.youtube.com/embed/uHiiMn2MG3I",
      idName: ""
    },
    {
      id: 4,
      title: "",
      subtitle: "",
      description: "The Role of Agriculture in Climate Change Mitigation",
      image: videoIimages,
      buttonText: "Watch Now",
      buttonIcon: playButton,
      videoUrl: "https://www.youtube.com/embed/l5F_onUlPp4",
      idName: ""
    },
    {
      id: 5,
      title: "",
      subtitle: "",
      description: "Climate Adaptation in Agriculture",
      image: videoIimages,
      buttonText: "Watch Now",
      buttonIcon: playButton,
      videoUrl: "https://www.youtube.com/embed/QK3Sg1tQ-rA",
      idName: ""
    },
    {
      id: 6,
      title: "",
      subtitle: "",
      description:
        "VCS Jurisdictional and Nested REDD+ Framework and ARR projects",
      image: videoIimages,
      buttonText: "Watch Now",
      buttonIcon: playButton,
      videoUrl: "https://www.youtube.com/embed/DS45A8on0ak",
      idName: ""
    }
  ];
  return (
    <div className="insightsVideos" ref={insightsVideos} id="video">
      <h4
        className={
          insightsVideosInView
            ? "animate__animated animate__fadeInUp"
            : "animate__animated animate__fadeOutUp"
        }
      >
        ALL VIDEOS
      </h4>
      <h3
        className={
          insightsVideosInView
            ? "animate__animated animate__fadeInUp"
            : "animate__animated animate__fadeOutDown"
        }
      >
        Unveiling the Unseen: A collection of In-Depth articles{" "}
      </h3>
      <div
        className="insightsVideoCards"
        // className={`insightsVideoCards ${
        //   insightsVideosCardInView
        //     ? "animate__animated animate__fadeInUp"
        //     : "animate__animated animate__fadeOutDown"
        // }`}
      >
        {articleVideos.map((article, index) => (
          <InsightVideoCard
            key={index}
            data={article}
            action={() => {
              const parts = article.videoUrl;
              setModalOpen(parts);
            }}
            videoRef={videoRef}
          />
        ))}
        <VideoModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
      </div>
    </div>
  );
};
export default InsightsVideos;
