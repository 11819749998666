import { useRef } from "react";
import { useInView } from "framer-motion";
import "animate.css";

// import LastSlideImgOne from "assets/images/home/slide3ImgOne.png";
// import LastSlideImgTwo from "assets/images/home/slide3ImgTwo.png";
// import LastSlideImgThree from "assets/images/home/slide3ImgThree.png";
// import LastSlideImgFive from "assets/images/home/slide3ImgFive.png";
// import LastSlideImgSix from "assets/images/home/slide3ImgSix.png";

import "components/InterestedPartner/interestedPartner.scss";
import { useNavigate } from "react-router-dom";

const InterestedPartner = () => {
  const interestedPartner = useRef();
  const navigate = useNavigate();
  const interestedPartnerIsInView = useInView(interestedPartner, {
    amount: 0.5,
    once: true
  });
  const handleNavItemSelect = () => {
    navigate("/contact-us");
  };
  return (
    <section className="interestedPartner" ref={interestedPartner}>
      <div
        // className="container interestedPartnerHeading"
        className={`container interestedPartnerHeading ${
          interestedPartnerIsInView
            ? "animate__animated animate__fadeInUp"
            : "animate__animated animate__fadeOutDown"
        }`}
      >
        {window.innerWidth > 768 ? (
          <>
            <h2>Interested in becoming a long term partner?</h2>
            <span>
              Explore enduring partnerships. Reach out to us for more.
            </span>
            {/* <button
              className={
                interestedPartnerIsInView
                  ? "animate__animated animate__fadeInUp"
                  : "animate__animated animate__fadeOutDown"
              }
              onClick={() => handleNavItemSelect("contact-us")}
            >
              Let’s Get Started
            </button> */}
            <button
              className={`commonButton ${
                interestedPartnerIsInView
                  ? "animate__animated animate__fadeInUp"
                  : "animate__animated animate__fadeOutDown"
              }`}
              onClick={() => handleNavItemSelect("contact-us")}
            >
              <label className="buttonText">Let’s Get Started</label>
            </button>
          </>
        ) : (
          <>
            <h2>Interested in becoming a long term partner?</h2>
            <button
              className={`commonButton ${
                interestedPartnerIsInView
                  ? "animate__animated animate__fadeInUp"
                  : "animate__animated animate__fadeOutDown"
              }`}
              onClick={() => handleNavItemSelect("contact-us")}
            >
              Let’s Get Started
            </button>
          </>
        )}
        {/* {window.innerWidth > 768 ? (
          <div className="aroundImages">
            <div
              className={
                interestedPartnerIsInView
                  ? "animate__animated animate__fadeInLeft"
                  : "animate__animated animate__fadeOutLeft"
              }
            >
              <img src={LastSlideImgOne} alt="LastSlideImgOne" />
            </div>
            <div
              className={
                interestedPartnerIsInView
                  ? "animate__animated animate__fadeInLeft"
                  : "animate__animated animate__fadeOutLeft"
              }
            >
              <img src={LastSlideImgTwo} alt="LastSlideImgOne" />
            </div>
            <div
              className={
                interestedPartnerIsInView
                  ? "animate__animated animate__fadeInLeft"
                  : "animate__animated animate__fadeOutLeft"
              }
            >
              <img src={LastSlideImgThree} alt="LastSlideImgOne" />
            </div>
            <div
              className={
                interestedPartnerIsInView
                  ? "animate__animated animate__fadeInRight"
                  : "animate__animated animate__fadeOutRight"
              }
            >
              <img src={LastSlideImgTwo} alt="LastSlideImgOne" />
            </div>
            <div
              className={
                interestedPartnerIsInView
                  ? "animate__animated animate__fadeInRight"
                  : "animate__animated animate__fadeOutRight"
              }
            >
              <img src={LastSlideImgFive} alt="LastSlideImgOne" />
            </div>
            <div
              className={
                interestedPartnerIsInView
                  ? "animate__animated animate__fadeInRight"
                  : "animate__animated animate__fadeOutRight"
              }
            >
              <img src={LastSlideImgSix} alt="LastSlideImgOne" />
            </div>
          </div>
        ) : (
          ""
        )} */}
      </div>
    </section>
  );
};

export default InterestedPartner;
