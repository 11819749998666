export const footerSvgs = {
  linkedIn: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6 21.5999V14.5679C21.6 11.1119 20.856 8.4719 16.824 8.4719C14.88 8.4719 13.584 9.5279 13.056 10.5359H13.008V8.7839H9.19199V21.5999H13.176V15.2399C13.176 13.5599 13.488 11.9519 15.552 11.9519C17.592 11.9519 17.616 13.8479 17.616 15.3359V21.5759H21.6V21.5999ZM2.71199 8.7839H6.69599V21.5999H2.71199V8.7839ZM4.70399 2.3999C3.43199 2.3999 2.39999 3.4319 2.39999 4.7039C2.39999 5.9759 3.43199 7.0319 4.70399 7.0319C5.97599 7.0319 7.00799 5.9759 7.00799 4.7039C7.00799 3.4319 5.97599 2.3999 4.70399 2.3999Z"
        fill="#829C96"
      />
    </svg>
  ),
  faceBook: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.415 23V12.9655H16.7818L17.2869 9.05372H13.415V6.55662C13.415 5.42442 13.7281 4.65284 15.3535 4.65284L17.4232 4.65199V1.15315C17.0652 1.10664 15.8366 1 14.4066 1C11.4206 1 9.37626 2.82266 9.37626 6.1692V9.05372H5.99924V12.9655H9.37626V23H13.415Z"
        fill="#829C96"
      />
    </svg>
  ),
  twitter: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_586_1219)">
        <path
          d="M24 4.5585C23.1075 4.95 22.1565 5.2095 21.165 5.3355C22.185 4.7265 22.9635 3.7695 23.3295 2.616C22.3785 3.183 21.3285 3.5835 20.2095 3.807C19.3065 2.8455 18.0195 2.25 16.6155 2.25C13.8915 2.25 11.6985 4.461 11.6985 7.1715C11.6985 7.5615 11.7315 7.9365 11.8125 8.2935C7.722 8.094 4.1025 6.1335 1.671 3.147C1.2465 3.8835 0.9975 4.7265 0.9975 5.634C0.9975 7.338 1.875 8.8485 3.183 9.723C2.3925 9.708 1.617 9.4785 0.96 9.117V9.171C0.96 11.562 2.6655 13.548 4.902 14.0055C4.5015 14.115 4.065 14.1675 3.612 14.1675C3.297 14.1675 2.979 14.1495 2.6805 14.0835C3.318 16.032 5.127 17.4645 7.278 17.511C5.604 18.8205 3.4785 19.6095 1.1775 19.6095C0.774 19.6095 0.387 19.5915 0 19.542C2.1795 20.9475 4.7625 21.75 7.548 21.75C16.602 21.75 21.552 14.25 21.552 7.749C21.552 7.5315 21.5445 7.3215 21.534 7.113C22.5105 6.42 23.331 5.5545 24 4.5585Z"
          fill="#829C96"
        />
      </g>
      <defs>
        <clipPath id="clip0_586_1219">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  youtube: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.4,8.6c-0.2-1.6-1.6-3-3.3-3.1c-9.8-0.7-14.6-0.7-24.3,0C2.2,5.6,0.8,7,0.6,8.6c-0.8,5.9-0.8,8.9,0,14.8
  c0.2,1.6,1.7,3,3.3,3.1C8.7,26.8,12.4,27,16,27s7.3-0.2,12.2-0.5c1.6-0.1,3-1.5,3.3-3.1C32.2,17.5,32.2,14.5,31.4,8.6z M21.1,17.7
  l-6,4C14.8,21.9,14.4,22,14,22c-0.3,0-0.7-0.1-0.9-0.2c-0.7-0.4-1.1-1-1.1-1.8V12c0-0.8,0.4-1.4,1.1-1.8c0.7-0.4,1.4-0.3,2.1,0.1
  l6,4c0.6,0.4,0.9,1,0.9,1.7S21.7,17.3,21.1,17.7z"
        fill="#829C96"
      />
    </svg>
  ),
  x: (
    <svg
      viewBox="0 0 1668.56 1221.19"
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
    >
      <g fill="#829C96" id="layer1" transform="translate(52.390088,-25.058597)">
        <path
          id="path1009"
          d="M283.94,167.31l386.39,516.64L281.5,1104h87.51l340.42-367.76L984.48,1104h297.8L874.15,558.3l361.92-390.99
    h-87.51l-313.51,338.7l-253.31-338.7H283.94z M412.63,231.77h136.81l604.13,807.76h-136.81L412.63,231.77z"
        />
      </g>
    </svg>
  )
};
