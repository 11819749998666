import { useRef } from "react";
import { useInView } from "framer-motion";
import "animate.css";

import { partnershipData } from "constant/partnership";

import "components/Partnership/partnership.scss";

const index = () => {
  const partnerShip = useRef();
  const partnerShipInView = useInView(partnerShip, { amount: 0.1, once: true });
  const partnerShiContentInView = useInView(partnerShip, {
    amount: 0.2,
    once: true
  });
  return (
    <section className="partnerShip" ref={partnerShip}>
      <div className="container">
        <div className="partnerShipHeading">
          <h2
            className={
              partnerShipInView
                ? "animate__animated animate__fadeInUp"
                : "animate__animated animate__fadeOutUp"
            }
          >
            OUR PARTNERSHIPS
          </h2>
          <h4
            className={
              partnerShipInView
                ? "animate__animated animate__fadeInUp"
                : "animate__animated animate__fadeOutUp"
            }
          >
            Allows for greater transparency trust and investment into
            nature-based projects
          </h4>
        </div>

        {partnershipData.map((partners) => (
          <div
            className={`partnerShipUniversity ${
              partnerShiContentInView
                ? "animate__animated animate__fadeInUp"
                : "animate__animated animate__fadeOutUp"
            }`}
            key={partners.id}
          >
            <div className="partnerShipImage">
              <img src={partners.image} alt={partners.name} />
            </div>

            <div className="partnerShipContent">
              <h2>{partners.name}</h2>

              {partners.description.map((data, index) => (
                <p key={index}>{data}</p>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default index;
