import Carousel from "components/Carousel/index";
import "./insights.scss";
import InsightsHero from "components/InsightsHero/index";
import InsightsArticles from "components/InsightsArticles/index";
import InsightsVideos from "components/InsightsVideos/index";
import { useEffect, useRef } from "react";

const Insights = () => {
  const videoRef = useRef();

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const storedData = localStorage.getItem("isVideoSection");

    if (storedData) {
      setTimeout(() => {
        const videoSelection = document.getElementById("video");
        if (videoSelection) {
          videoSelection.scrollIntoView({ behavior: "smooth" });
          setTimeout(() => {
            videoRef.current?.click();
          }, 2000);
        }
      }, 1000);

      // eslint-disable-next-line no-undef
      localStorage.removeItem("isVideoSection");
    }
  }, []);

  return (
    <div className="insightHeader">
      <div className="insights">
        <InsightsHero />
        <Carousel />
        <InsightsArticles />
        <InsightsVideos videoRef={videoRef} />
      </div>
    </div>
  );
};

export default Insights;
