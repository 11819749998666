import React, { useEffect, useState, useRef } from "react";
import geoTreeLogo from "assets/images/home/geotree-logo.svg";
import { contentsData } from "constant/contents";
import "components/SatelliteData/satelliteData.scss";
import SatelliteResponsive from "components/SatelliteResponsive/index";

const SatelliteData = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [activeHeight, setActiveHeight] = useState(80);
  const [isActiveEffect, setIsActiveEffect] = useState(false);
  const ourTechTitleRef = useRef([]);
  const timerRef = useRef(null);

  const handleClick = (number) => {
    setCurrentStep(number);
    setIsActiveEffect(true);
    resetTimer();
  };

  const resetTimer = () => {
    clearInterval(timerRef.current);
    timerRef.current = setInterval(() => {
      setCurrentStep((prevStep) =>
        prevStep === contentsData.length ? 1 : prevStep + 1
      );
    }, 4000);
  };

  useEffect(() => {
    setCurrentStep(1);
    resetTimer();
    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  useEffect(() => {
    const height =
      ourTechTitleRef?.[currentStep]?.children[1]?.offsetHeight || 0;
    setActiveHeight(height + 88);
    setIsActiveEffect(true);

    setTimeout(() => {
      setIsActiveEffect(false);
    }, 3000);
  }, [currentStep]);

  const isMobile = window.innerWidth <= 992;

  return (
    <section>
      <div className="container">
        <div className="ourTech">
          <div className="commonHeading">
            <h1>Our Technology</h1>
            {isMobile && (
              <p>Leveraging Satellite Data & A.I. To Analyze Carbon Projects</p>
            )}
          </div>

          {!isMobile && (
            <div className="ourTechHeader">
              <h3>
                Leveraging Satellite Data & A.I. To Analyze Carbon Projects
              </h3>
              <div className="ourTechLogo">
                <img src={geoTreeLogo} alt="geoTreeLogo" />
              </div>
            </div>
          )}
        </div>

        {isMobile ? (
          <SatelliteResponsive />
        ) : (
          <div className="ourTechCards">
            <div
              className={`ourTechVideo ${isActiveEffect ? "activeData" : ""}`}
              // className="ourTechVideo activeData"
            >
              <video
                key={currentStep}
                src={contentsData[currentStep - 1]?.image}
                type="video/mp4"
                muted
                loop
                className="posterData"
                playsInline
                autoPlay
              />
            </div>

            <div className="ourTechProcess">
              {contentsData?.map((step) => (
                <div
                  key={step.number}
                  className={`ourTechProcessContents ${
                    step.number === currentStep ? "stepActive" : ""
                  }`}
                  onClick={() => handleClick(step.number)}
                  style={{
                    height: step.number === currentStep ? activeHeight : 80
                  }}
                >
                  <div
                    className="ourTechTitle"
                    ref={(ele) => (ourTechTitleRef[step.number] = ele)}
                  >
                    <h3>{step.title}</h3>
                    <p className="commonParaGraph">{step.about}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default SatelliteData;
