import HomeBanner from "components/HomeBanner";
import Concepts from "components/Concepts/index";
import Partners from "components/Partners/index";
// import Technology from "components/Technology";
// import GetInTouch from "components/GetInTouch";
/*import UserStories from "components/UserStories/index";*/
// import HomeInsights from "components/HomeInsights/index";
import InterestedPartner from "components/InterestedPartner/index";
// import Loader from "components/Loader/index";
import SatelliteData from "components/SatelliteData";
import MRVLifeCycle from "components/MRVLifeCycle";
import CarbonChain from "components/CarbonChain";
import CarbonCarousel from "components/CarbonCarousel";
import InsightSection from "components/InsightSection";

const index = () => {
  return (
    <>
      {/* <Loader /> */}
      <HomeBanner />
      <Partners />
      <MRVLifeCycle />
      <Concepts />
      <CarbonChain />
      <CarbonCarousel />
      {/* <Technology /> */}
      <SatelliteData />
      {/*<UserStories />*/}
      {/* <HomeInsights /> */}
      {/* <GetInTouch /> */}
      <InsightSection />
      <InterestedPartner />
    </>
  );
};

export default index;
