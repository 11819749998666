export const navItems = [
  {
    title: "Home",
    route: "/",
    path: ""
  },
  {
    title: "Product",
    route: "/products",
    path: "products"
  },
  {
    title: "About Us",
    route: "/about-us",
    path: "about-us"
  },
  {
    title: "Insights",
    route: "/insights",
    path: "insights"
  },
  {
    title: "News",
    route: "/news",
    path: "news"
  }
];
