import NewsBanner from "components/NewsBanner";
import NewsBlog from "components/NewsBlog";

const News = () => {
  return (
    <>
      <NewsBanner />
      <NewsBlog />
    </>
  );
};

export default News;
