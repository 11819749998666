import { useRef } from "react";
import { useInView } from "framer-motion";
import "animate.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import slide1 from "assets/images/Ellipse-1.png";
import slide2 from "assets/images/Ellipse-2.png";
import slide3 from "assets/images/Ellipse-3.png";
import slide4 from "assets/images/Ellipse-4.png";
import arrowLeft from "assets/icons/arrowLeft.svg";

import "swiper/css";
import "swiper/css/pagination";

import "./geoTreeEdge.scss";
const GeoTreeEdge = () => {
  const geoTree = useRef();
  const geoTreeInView = useInView(geoTree, { amount: 0.1, once: true });
  const geoTreeSlideInView = useInView(geoTree, { amount: 0.2, once: true });
  return (
    <div className="geoTree" ref={geoTree}>
      <div className="geoTreeHeading">
        <h4
          className={
            geoTreeInView
              ? "animate__animated animate__fadeInUp"
              : "animate__animated animate__fadeOutUp"
          }
        >
          THE GEOTREE EDGE
        </h4>
        <h3
          className={
            geoTreeInView
              ? "animate__animated animate__fadeInUp"
              : "animate__animated animate__fadeOutUp"
          }
        >
          World-leading expertise in remote sensing and carbon markets
        </h3>
      </div>

      <div
        className={`geoTreeCarousel ${
          geoTreeSlideInView
            ? "animate__animated animate__fadeInUp"
            : "animate__animated animate__fadeOutUp"
        }`}
      >
        <Swiper
          className="geoTreeSlider"
          effect={"coverflow"}
          grabCursor={false}
          centeredSlides={true}
          spaceBetween={0}
          loop={false}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 2.5
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false
          }}
          modules={[Autoplay, Pagination, Navigation]}
          pagination={{ el: ".swiperPagination", clickable: true }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            disabledClass: "swiper-button-disabled"
          }}
        >
          <SwiperSlide className="slide">
            <div className="slideContent">
              <h4>01</h4>
              <h5>
                Solving the need for cost effective and robust monitoring tools
              </h5>
              <p>
                Currently, the industry depends on costly monitoring methods. We
                leverage technology to significantly reduce costs whilst
                allowing continuous monitoring.
              </p>
            </div>
            <div className="slideImage">
              <img src={slide1} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide">
            <div className="slideContent">
              <h4>02</h4>
              <h5>
                Connecting local nature based carbon programs to the global
                carbon market
              </h5>
              <p>
                Our technology facilitates the creation of high-quality carbon
                offsets with global visibility.
              </p>
            </div>
            <div className="slideImage">
              <img src={slide2} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide">
            <div className="slideContent">
              <h4>03</h4>
              <h5>
                Addressing the disconnect between farm carbon programs and
                global carbon markets
              </h5>
              <p>
                The existing carbon market infrastructure wasn't designed for
                agriculture, but we act as a bridge between these two worlds.
              </p>
            </div>
            <div className="slideImage">
              <img src={slide3} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide className="slide">
            <div className="slideContent">
              <h4>04</h4>
              <h5>
                Supporting global farmers, project developers and landowners of
                all sizes who aren't supported by current programs
              </h5>
              <p>
                We view the entire world from a 10 x 10 metre perspective.
                Therefore, no matter their size projects can use our technology.
              </p>
            </div>
            <div className="slideImage">
              <img src={slide4} alt="" />
            </div>
          </SwiperSlide>
          <div className="paginationArrows">
            <img src={arrowLeft} className="swiper-button-prev" alt="" />
            <div className="swiperPagination"></div>
            <img src={arrowLeft} className="swiper-button-next" alt="" />
          </div>
        </Swiper>
      </div>
    </div>
  );
};

export default GeoTreeEdge;
