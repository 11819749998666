import React, { useEffect } from "react";
import "animate.css";

import productGlobe from "assets/icons/productGlobe.svg";
import geoLocation from "assets/icons/geoLocation.svg";
import carbonCloud from "assets/icons/carbonCloud.svg";
import antennaDish from "assets/icons/antennaDish.svg";
import plant from "assets/icons/plant.svg";
import satellite from "assets/icons/satellite.svg";
import geoTreeLogo from "assets/images/geotreeLogo.svg";

import "./productHero.scss";
const ProductHero = () => {
  const handleScroll = () => {
    const circles = document.querySelectorAll(".heroCircle");

    circles.forEach((circle, index) => {
      if (index % 2 === 0) {
        circle.style.transform = `translate(-50%, -50%) rotate(${
          window.scrollY / 40
        }deg)`;
      } else {
        circle.style.transform = `translate(-50%, -50%) rotate(-${
          window.scrollY / 40
        }deg)`;
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const handleKnowMore = () => {
    const sectionElement = document.getElementById(`productFeatures`);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="productHero">
      <div className="productHeroBack hero">
        <div
          className="heroCircle circle-1"
          style={{
            transform: "translate(-50%, -50%) rotate(0deg)"
          }}
        >
          <div className="heroIcon heroIcon-1 hero-red">
            <img src={carbonCloud} alt="" />
          </div>
          <div className="heroIcon heroIcon-2 hero-yellow">
            <img src={geoLocation} alt="" />
          </div>
        </div>

        <div
          className="heroCircle circle-2"
          style={{
            transform: "translate(-50%, -50%) rotate(0deg)"
          }}
        >
          <div className="heroIcon heroIcon-1 hero-blue">
            <img src={productGlobe} alt="" />
          </div>
          <div className="heroIcon heroIcon-2 hero-red">
            <img src={antennaDish} alt="" />
          </div>
        </div>

        <div
          className="heroCircle circle-3"
          style={{
            transform: "translate(-50%, -50%) rotate(0deg)"
          }}
        >
          <div className="heroIcon heroIcon-1 hero-yellow">
            <img src={plant} alt="" />
          </div>
          <div className="heroIcon heroIcon-2 hero-green">
            <img src={satellite} alt="" />
          </div>
        </div>

        <div
          className="heroCircle circle-4"
          style={{
            transform: "translate(-50%, -50%) rotate(0deg)"
          }}
        ></div>
      </div>

      <div className="productHeroContent">
        <img src={geoTreeLogo} alt="logo" />
        <h2>Providing scalable institutional grade 4D-MRV technology</h2>
        <p className="animate__animated animate__fadeInUp">
          We set the standard for due diligence, design and monitoring of nature
          based projects.
        </p>

        {/* <button
          className="animate__animated animate__fadeInUp"
          onClick={handleKnowMore}
        >
          Know more
        </button> */}
        <button
          className="commonButton animate__animated animate__fadeInUp"
          onClick={handleKnowMore}
        >
          <label className="buttonText">Know more</label>
        </button>
      </div>
    </div>
  );
};

export default ProductHero;
