import React from "react";
import "animate.css";

import "./insightsHero.scss";
const InsightsHero = () => {
  return (
    <div className="insightsHero">
      <h2 className="insightsHeroHeading animate__animated animate__fadeInUp">
        Get a closer look into what’s cooking inside
      </h2>
      <p className="insightsHeroContent animate__animated animate__fadeInUp">
        Learn how we can work together to unlock carbon removal at a gigaton
        scale through science-backed technology.
      </p>
    </div>
  );
};

export default InsightsHero;
