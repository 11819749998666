import React, { useEffect, useState } from "react";
import close from "assets/icons/close.svg";
import "components/VideoModal/videoModal.scss";

const VideoModal = ({ modalOpen, setModalOpen }) => {
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0
  });

  useEffect(() => {
    const body = document.querySelector("body");
    if (modalOpen) {
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
  }, [modalOpen]);
  const handleScroll = (val) => {
    const body = document.querySelector("body");
    if (!val) {
      body.style.overflow = "scroll";
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 500) {
        setDimensions({
          width: window.innerWidth,
          height: window.innerHeight / 2
        });
      } else {
        setDimensions({
          width: window.innerWidth / 1.6,
          height: window.innerHeight / 1.32
        });
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleClose = () => {
    setModalOpen(false);
    handleScroll(false);
  };

  return (
    modalOpen && (
      <div className="videoModal" id="openModal">
        <div className="videoModalContainer">
          <img src={close} alt="close.svg" onClick={handleClose} />
          <iframe
            width={dimensions.width}
            height={dimensions.height}
            src={`${modalOpen}`}
            title="YouTube video player"
            allowFullScreen
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          ></iframe>
        </div>
      </div>
    )
  );
};

export default VideoModal;
