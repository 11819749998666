import university from "assets/images/university.png";
import content from "assets/images/content.png";
import spire from "assets/images/spire.png";

export const partnershipData = [
  {
    id: 1,
    image: university,
    name: "University of Cambridge",
    description: [
      "We work closely with the Computer Science Department along with The Cambridge Centre for Carbon Credits which is an interdisciplinary group founded in 2021 that is run across the Department of Computer Science, Zoology and Plant Sciences.",

      "It is housed in the Conservation Research Institute in the David Attenborough Building."
    ]
  },

  {
    id: 2,
    image: content,
    name: "Maxar",
    description: [
      "Maxar is the first and largest company to deliver native 30 cm resolution and derived 15 cm high-definition imagery, delivering clearer, richer images that empower better decision-making. We work with Maxar to provide clients with high resolution land management monitoring and assessment solutions.",

      "This is particularly useful for monitoring deforestation and also for assessing tree canopy heights."
    ],
    className: "partnerShipData"
  },

  {
    id: 3,
    image: spire,
    name: "Spire",
    description: [
      "Spire has one of the world’s largest constellations of nano satellites orbiting in close proximity to Earth. Using an innovative technique called radio occultation to collect thousands of atmospheric readings a day, Spire monitors pressure, humidity and temperature 24/7 across all points of the globe from the most remote regions to open oceans.",

      "We leverage Spire’s weather data, to provide a revolutionary weather risk assessment and monitoring product to support nature based carbon projects."
    ]
  }
];
