import React from "react";
import "animate.css";

import animationEarth from "../../assets/images/about-us/rotatingEarth.gif";

import "./ourMissions.scss";

function OurMissions() {
  return (
    <div className="ourMissions">
      <div className="ourMissionsTitleSection">
        <h2 className="animate__animated animate__fadeInUp">Who we are</h2>
        <p className="animate__animated animate__fadeInUp">
          Mantle Labs utilises remote sensing, environmental science and machine
          learning to analyse and support forestry and agriculture projects
          which support climate change mitigation and resilience. Mantle Labs
          serves leading carbon market clients and has staff which includes
          leading academics and industry practitioners.
        </p>
      </div>
      <div className="rotaingEathContainer animate__animated animate__fadeInUp">
        <img className="rotaingEath " src={animationEarth} alt="" />
      </div>
    </div>
  );
}

export default OurMissions;
