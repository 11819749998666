import close from "assets/images/about-us/close.png";

const PopupManagement = ({ teamMember, onClose }) => {
  return (
    <div className="ourTeamPopupWindow">
      <div className="ourTeamPopup">
        <div className="ourTeamPopupImg">
          <img src={close} alt="close" onClick={onClose} />
        </div>

        <div className="ourTeamPopupContent">
          <img src={teamMember.image} alt={teamMember.name} />

          <div className="ourTeamPopupData">
            <h4>{teamMember.name}</h4>
            <p>{teamMember.designation}</p>

            <ul>
              {teamMember.description.map((para, index) => (
                <li key={index}>{para}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupManagement;
