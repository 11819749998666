import { useRef } from "react";
import { useInView } from "framer-motion";
import "animate.css";

import "components/ContactForm/contactForm.scss";
import EnquirySent from "components/sendEnquiry/EnquirySent";
import { useState } from "react";

const contactForm = () => {
  const [enquirySent, setEnquirySent] = useState(false);
  const [enquirySentStatusObj, setEnquirySentStatus] = useState({
    message: "INITIAL",
    status: false,
    open: false
  });

  const form = useRef();
  const formInView = useInView(form, { amount: 0.1, once: true });

  const enqDetails = {
    name: "",
    company: "",
    emailId: "",
    message: ""
  };

  const enqInputHandler = (event) => {
    if (enqDetails != undefined) {
      enqDetails["" + event.target.id] = event.target.value;
    }
  };

  const getEnquirySentStatus = () => {
    return enquirySentStatusObj;
  };

  const updateObject = (o, s, m) => {
    return setEnquirySentStatus({ open: o, status: s, message: m });
  };

  var enquirySentDiv = enquirySent ? (
    <EnquirySent
      open={enquirySent}
      close={() => setEnquirySent(false)}
      status={getEnquirySentStatus().status}
      errorMessage={getEnquirySentStatus().message}
    />
  ) : (
    ""
  );

  const validateEnquiry = () => {
    if (enqDetails !== undefined) {
      if (
        enqDetails.name.trim().length <= 0 ||
        enqDetails.company.trim().length <= 0 ||
        enqDetails.emailId.trim().length <= 0 ||
        enqDetails.message.trim().length <= 0 ||
        !enqDetails.emailId.includes("@")
      ) {
        console.log("Validation failed");
        return false;
      } else {
        return true;
      }
    } else {
      updateObject(true, false, "Something weird happened");
      return false;
    }
  };

  const handleEnquiry = (event) => {
    event.preventDefault();
    if (enqDetails != undefined) {
      console.log(enqDetails);

      if (validateEnquiry()) {
        var myHeaders = new Headers();
        myHeaders.append("accept", "*/*");
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(enqDetails);

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };

        fetch(
          "https://mantle-labs.com/mantlelabsapi/mailDemoRequest",
          requestOptions
        )
          .then((response) => response.json())
          .then((response) => {
            console.log(response);
            //var result = JSON.parse(response.result);
            var result = response;

            updateObject(
              true,
              false,
              "Currently we are not accepting the details you have sent"
            );
            if (result.statusCode === 5) {
              updateObject(
                true,
                false,
                "Either already registered or domain blocked or invalid information. You can still send email to info@mantle-labs.com."
              );
            } else {
              updateObject(true, true, "Enquiry Sent Successfully");
            }

            setEnquirySent(true);
          })
          .catch((error) => {
            console.log("error", error);
            updateObject(
              true,
              false,
              "Something went wrong, please try again later or send details to info@mantle-labs.com"
            );
            setEnquirySent(true);
          });
      } else {
        updateObject(
          true,
          false,
          "Your enquiry has not been sent. Please provide valid details"
        );
        setEnquirySent(true);
      }
    }
  };
  return (
    <>
      <section className="form" ref={form}>
        <div className="container">
          <div className="formHeading">
            <h3
              className={
                formInView
                  ? "animate__animated animate__fadeInUp"
                  : "animate__animated animate__fadeOutUp"
              }
            >
              Let’s build a resilient world together
            </h3>
            <p
              className={
                formInView
                  ? "animate__animated animate__fadeInUp"
                  : "animate__animated animate__fadeOutUp"
              }
            >
              Learn how we can work together in having personnel who are at the
              forefront of compliance and voluntary carbon markets
            </p>
          </div>

          <form className="formDetails">
            <div className="formContents">
              <label>My name is</label>
              <input
                id="name"
                type="text"
                placeholder="Your name"
                onChange={enqInputHandler}
              />
            </div>

            {/*<div className="formList">
              <label> I am a</label>
              <ul>
                <li>
                  <input type="radio" id="Carbon" name="carbon" />
                  <label htmlFor="Carbon">Carbon project developer</label>
                </li>
                <li>
                  <input type="radio" id="Crop-input" name="carbon" />
                  <label htmlFor="Crop-input">Crop input company</label>
                </li>
                <li>
                  <input type="radio" id="Crop" name="carbon" />
                  <label htmlFor="Crop">Crop machinery</label>
                </li>
                <li>
                  <input type="radio" id="Large" name="carbon" />
                  <label htmlFor="Large">Large cooperate</label>
                </li>
                <li>
                  <input type="radio" id="Agri" name="carbon" />
                  <label htmlFor="Agri">Agri commodities trader</label>
                </li>
                <li>
                  <input type="radio" id="Food" name="carbon" />
                  <label htmlFor="Food">Food retailer</label>
                </li>
                <li>
                  <input type="radio" id="Government" name="carbon" />
                  <label htmlFor="Government">Government</label>
                </li>
              </ul>
            </div>*/}

            <div className="formContents">
              <label>Working for?*</label>
              <input
                id="company"
                type="text"
                placeholder="Your company name"
                onChange={enqInputHandler}
              />
            </div>
            <div className="formGroups">
              <label>My email is</label>
              <input
                id="emailId"
                type="text"
                placeholder="Your mail"
                onChange={enqInputHandler}
              />
              <label>I want to enquire about</label>
            </div>
            <div className="formContents">
              <input
                id="message"
                type="text"
                placeholder="Your enquiry"
                onChange={enqInputHandler}
              />
            </div>
          </form>
          <div className="formButton">
            <button onClick={handleEnquiry}>Send Enquiry</button>
          </div>
        </div>
        {/* <EnquirySent open={enquirySent} 
        close={() => { setEnquirySent(false);}}
        status={getEnquirySentStatusObj().status} 
        errorMessage={getEnquirySentStatusObj().message} />*/}

        {enquirySentDiv}
      </section>
    </>
  );
};

export default contactForm;
