import jonPierre from "assets/images/about-us/jonPiere.png";
import clement from "assets/images/about-us/clement.png";
import swapnil from "assets/images/about-us/swapnil.png";
import rishi from "assets/images/about-us/rishi.png";
import andrewBlake from "assets/images/about-us/andrew.png";

export const managementTeam = [
  {
    name: "Jon Pierre",
    image: jonPierre,
    designation: "CEO",
    description: [
      "Former agriculture options trader at leading firms including Mercuria, Hartree, Citi and Goldman Sachs",
      "Oxford, Cambridge, UCL alum",
      "Advisor to the UK Space Agency and Satellite Catapult"
    ]
  },
  {
    name: "Dr Clement Atzberger",
    image: clement,
    designation: "Head of Research",
    description: [
      "Full Professor at BOKU University in Vienna and Head of the Institute of Geomatics",
      "Leading remote sensing expert with 30+ years’ experience",
      "Advisor to the European Space Agency"
    ]
  },
  {
    name: "Swapnil Baokar",
    image: swapnil,
    designation: "Director",
    description: [
      "Serial entrepreneur with over 15 years experience in risk management and financial markets",
      "Experienced in founding and scaling start-ups across edutech, fintech and agritech"
    ]
  },
  {
    name: "Rishi Sapre",
    image: rishi,
    designation: "Director",
    description: [
      "Specializes in product design for machine learning/artificial intelligence-based systems",
      "Experience covers projects in geographies such as UK, North America, Africa and APAC"
    ]
  },
  {
    name: "Dr Andrew Blake",
    image: andrewBlake,
    designation: "Chief Scientific Advisor",
    description: [
      "Founding Director at the Alan Turing Institute, before founding the Samsung AI Centre in Cambridge in 2018",
      "Previously Laboratory Director of Microsoft Research Cambridge",
      "Fellow of the Royal Society and Royal Academy of Engineering"
    ]
  }
];
