import { useRef } from "react";
import { useInView } from "framer-motion";
import Marquee from "react-fast-marquee";
import "animate.css";

import "components/Partners/partners.scss";

// import lineImage from "assets/images/home/sideLine.svg";
// images
import { partnerImages } from "constant/partners";

const Partners = () => {
  const partners = useRef();
  const partnersInView = useInView(partners, { amount: 0.1, once: true });
  const partnersLogosInView = useInView(partners, { amount: 0.1, once: true });
  return (
    <section className="partners" ref={partners}>
      <div className="container partnersContainer">
        <div className="commonHeading">
          <h1
            className={
              partnersInView
                ? "animate__animated animate__fadeInUp"
                : "animate__animated animate__fadeOutUp"
            }
          >
            {/* <img src={lineImage} alt="image" /> */}
            {/* Our Clients and Partners */}
            OUR CLIENTS AND PARTNERS
            {/* <img src={lineImage} alt="image" /> */}
          </h1>
          <p
            className={
              partnersInView
                ? "animate__animated animate__fadeInUp"
                : "animate__animated animate__fadeOutUp"
            }
          >
            Trusted by many of the world’s leading businesses
          </p>
        </div>

        {/* images */}
        <div
          className={`partnersLogo ${
            partnersLogosInView
              ? "animate__animated animate__fadeInUp"
              : "animate__animated animate__fadeOutUp"
          }`}
        >
          <Marquee direction="left" play="true" speed="100">
            <div
              className={`partnersLogoStyle ${
                partnersLogosInView
                  ? "animate__animated animate__fadeInUp"
                  : "animate__animated animate__fadeOutUp"
              }`}
            >
              {partnerImages.map((image, index) => (
                <span key={index}>
                  <img src={image.src} alt={image.name} />
                </span>
              ))}
            </div>
          </Marquee>
        </div>
      </div>
    </section>
  );
};

export default Partners;
