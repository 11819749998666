import { Link } from "react-router-dom";

import { footerItems } from "constant/footer";

import "components/Footer/footer.scss";

// image
import { footerSvgs } from "constant/svgLogos";
import locationIcon from "assets/images/locationIcon.svg";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <Link to={"https://maps.app.goo.gl/aRnYrzczhgGH32Zs8"} target="_blank">
          <div className="footerLocation">
            <img src={locationIcon} alt="locationIcon" />
            <p>29 FARM STREET, LONDON W1J 5RL</p>
          </div>
        </Link>
      </div>

      <div className="container footerContainer">
        <h5>© 2024 Mantle Labs Limited.</h5>

        {/* footer Items */}
        <ul className="footerList">
          {footerItems.map((footerItem, index) => (
            <li key={index}>
              <span>{footerItem.title}</span>
            </li>
          ))}
        </ul>

        <div className="footerLinks">
          <Link
            to="https://www.linkedin.com/company/mantle-labs-limited/mycompany/"
            target="_blank"
          >
            {footerSvgs.linkedIn}
          </Link>
          <Link to="https://www.youtube.com/@mantlelabs3295" target="_blank">
            {footerSvgs.youtube}
          </Link>
          <Link to="https://twitter.com/mantlelabs" target="_blank">
            {footerSvgs.x}
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
