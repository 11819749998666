import { useEffect, useRef, useState } from "react";

// import { Link } from "react-router-dom";

import "components/OurField/ourfield.scss";

// import geoTreeLogo from "assets/images/geotreeLogo.svg";

const OurField = () => {
  const [counter, setCounter] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    let interval;
    const handleScroll = () => {
      if (ref.current.getBoundingClientRect()?.top < 800) {
        interval = setInterval(() => {
          setCounter((prevCount) => prevCount + 1);
        }, 100);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      clearInterval(interval);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //function
  const counterDisplay = (countNumber) => {
    return counter > countNumber ? (
      <>
        {countNumber}
        <span className="countSymbol">+</span>
      </>
    ) : (
      counter
    );
  };

  return (
    <section className="ourField">
      {/* <div className="ourFieldBgImage">
        <div className="container ">
          <div>
            <img src={geoTreeLogo} alt="logo" />
            <h2>Providing scalable institutional grade MRV technology</h2>
            <p>supporting nature-based carbon projects.</p>

            <Link to="/products">
              <button>Know more</button>
            </Link>
          </div>
        </div>
      </div> */}

      <div className="container ourFieldContainer" ref={ref}>
        <div className="ourFieldExperience">
          <label>{counterDisplay(50)}</label>
          <p>Years experience in Remote Sensing, AI & Agriculture</p>
        </div>

        <div className="ourFieldExperience">
          <label>{counterDisplay(40)}</label>
          <p>Years experience in Carbon Market</p>
        </div>
        <div className="ourFieldExperience">
          <label>{counterDisplay(30)}</label>
          <p>Years commodity risk management experience</p>
        </div>

        <div className="ourFieldExperience">
          <label>{counterDisplay(20)}</label>
          <p>Years on the ground forest & landscape project experience</p>
        </div>
      </div>
      <div className="centerLine"></div>
    </section>
  );
};

export default OurField;
