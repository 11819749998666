import digitalImg from "assets/images/home/digital.svg";
import diligenceImg from "assets/images/home/diligence.svg";
import designImg from "assets/images/home/design.svg";
import measureImg from "assets/images/home/measure.svg";
import reportImg from "assets/images/home/report.svg";
import verifyImg from "assets/images/home/verify.svg";

export const conceptData = [
  {
    title: "Digital",
    description:
      "Powered by remote sensing & AI models whilst providing a portal for uploading and viewing of data.",
    className: "Measure",
    image: digitalImg
  },
  {
    title: "Due Diligence",
    description:
      "Robust and science-based analysis of project feasibility including biogeochemical modelling & risk analysis.",
    className: "Report",
    image: diligenceImg
  },
  {
    title: "Design",
    description:
      "Guidance on stratification for activities such as ground sampling & implementation.",
    className: "Verify",
    image: designImg
  },
  {
    title: "Measure",
    description:
      "Tracking & documentation of data & information on GHG emissions or emissions reductions.",
    className: "Measure",
    image: measureImg
  },
  {
    title: "Report",
    description:
      "Reporting entails the dissemination of measured GHG emissions & emissions reduction",
    className: "Report",
    image: reportImg
  },
  {
    title: "Verify",
    description:
      "Verification is an independent assessment of reported GHG emissions & emissions reductions.",
    className: "Verify",
    image: verifyImg
  }
];
