import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import { navItems } from "constant/header";

// styles
import "components/Header/header.scss";

// assets
import mantleLabLogo from "assets/images/mantleLabLogos.svg";
import mantleLogos from "assets/images/mantleLogos.svg";
import menuIcon from "assets/images/menu.svg";
import menuIconResponisve from "assets/images/menuIcon.svg";
import closeIcon from "assets/images/close.svg";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [iconSource, setIconSource] = useState(menuIcon);
  const [position, setPosition] = useState(0);
  const [hideHeader, setHideHeader] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;
  const headerRef = useRef();

  useEffect(() => {
    document.body.style.overflow = isMenuOpen ? "hidden" : "auto";
  }, [isMenuOpen, pathname]);

  const darkURLs = [
    "/contact-us",
    "/products",
    "/insights",
    "/about-us",
    "/",
    "/news"
  ];

  useEffect(() => {
    if (darkURLs?.some((path) => path === pathname)) {
      setIconSource(isMenuOpen ? closeIcon : menuIconResponisve);
    } else {
      setIconSource(isMenuOpen ? closeIcon : menuIcon);
    }
  }, [isMenuOpen, pathname]);

  useEffect(() => {
    const handleScroll = () => {
      let moving = window.pageYOffset;
      if (moving > 100) {
        setHideHeader(position > moving);
      } else {
        setHideHeader(true);
      }
      setPosition(moving);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  const cls = hideHeader ? "" : "hidden";

  useEffect(() => {
    function handleResize() {
      const headerHeight = isMenuOpen
        ? window.innerHeight - 64
        : window.innerHeight;
      document.documentElement.style.setProperty(
        "--header-height",
        `${headerHeight}px`
      );
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    setIconSource(menuIcon);
  };

  const handleNavItemSelect = () => {
    setIsMenuOpen(false);
    setIconSource(menuIcon);
  };

  const handleNavigate = () => {
    navigate("/contact-us");
    handleNavItemSelect("contact-us");
  };

  return (
    <header
      ref={headerRef}
      className={`header ${isMenuOpen ? "headerOpen" : ""} ${
        darkURLs?.some((path) => path === pathname) ? "headerContact" : ""
      } ${cls}`}
      // style={{ transform: hideHeader ? "translateY(-100%)" : "translateY(0)" }}
    >
      <div className="container headerContainer">
        {darkURLs?.some((path) => path === pathname) ? (
          <Link to="/" className="headerLogo">
            <img src={mantleLogos} alt="menuBar" />
          </Link>
        ) : (
          <Link to="/" className="headerLogo">
            <img src={isMenuOpen ? mantleLogos : mantleLabLogo} alt="menuBar" />
          </Link>
        )}

        {/* navItems */}

        <ul className={`headerElements ${isMenuOpen ? "headerOpen" : ""}`}>
          <div className="listStyle">
            {navItems.map((navItem, index) => (
              <li
                key={index}
                className={`no-wrap  ${
                  pathname.split("/")[1] === navItem.path && "/"
                    ? "navActive"
                    : ""
                }`}
                onClick={() => {
                  navigate(navItem.route);
                  handleNavItemSelect(navItem.route);
                }}
              >
                <Link to={navItem.route}>{navItem.title}</Link>
              </li>
            ))}
          </div>
          {/* <Link
            to="contact-us"
            className="headerContactUs responsiveButton"
            onClick={() => handleNavItemSelect("contact-us")}
          >
            Contact Us
          </Link> */}
          <button
            className="commonButton headerContactUs responsiveButton"
            onClick={handleNavigate}
          >
            <label className="buttonText">Contact Us</label>
          </button>
        </ul>

        {/* <Link
          to="contact-us"
          className="headerContactUs"
          onClick={() => handleNavItemSelect("contact-us")}
        >
          Let’s Get Started
        </Link> */}
        <button
          className="commonButton headerContactUs"
          onClick={handleNavigate}
        >
          <label className="buttonText">Contact Us</label>
        </button>

        {/* Menu icon */}
        <div className="headerMenuIcon">
          <img
            src={iconSource}
            alt="menuBar"
            className={
              isMenuOpen ? "headerSideBarOpen" : "headerSideBarCloseIcon"
            }
            onClick={isMenuOpen ? closeMenu : toggleMenu}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
