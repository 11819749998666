import OurTechnology from "components/OurTechnology/index";
import GeoTreeEdge from "components/GeoTreeEdge/index";
import ProductFeatures from "components/ProductFeatures/index";
import ProductHero from "components/ProductHero/index";
import ProductContent from "components/ProductContent/index";
import InterestedPartner from "components/InterestedPartner/index";

import "containers/Product/product.scss";

const Product = () => {
  return (
    <div className="products">
      <div className="product">
        <ProductHero />
        <ProductFeatures />
        <OurTechnology />
        <GeoTreeEdge />
        <ProductContent />
        <InterestedPartner />
      </div>
    </div>
  );
};

export default Product;
