import { useNavigate } from "react-router-dom";

// import lineImage from "assets/images/home/sideLine.svg";
import navigator from "assets/images/home/navigator.svg";

// fourCardImage
import fostering from "assets/images/home/insightHarvesting.png";
import bgFosteringImg from "assets/images/home/insightBgImage.png";
import forestImg from "assets/images/home/insightForest.png";
import bgVideo from "assets/images/home/insightVideo.png";

import fosteringMobile from "assets/images/home/insightHarvestingMobile.png";
import bgFosteringImgMobile from "assets/images/home/insightBgImgMobile.png";
import forestImgMobile from "assets/images/home/insightForestMobile.png";
import bgVideoMobile from "assets/images/home/insightVideoImage.png";

import "components/InsightSection/insightSection.scss";
// import { useRef } from "react";

const InsightSection = () => {
  const navigate = useNavigate();
  // const videoRef = useRef();

  const handleVideoClick = () => {
    navigate("/insights");
    // eslint-disable-next-line no-undef
    localStorage.setItem("isVideoSection", true);
  };

  const isMobile = window.innerWidth <= 992;

  return (
    <section className="insight">
      <div className="container">
        <div className="commonHeading">
          <h1>
            {/* <img src={lineImage} alt="image" /> */}
            Insights
            {/* <img src={lineImage} alt="image" /> */}
          </h1>
          <p>Get a Closer Look Into What’s Cooking Inside</p>
        </div>

        <div className="insightNavigation">
          <div
            className="insightImage"
            id="fostering"
            onClick={() => navigate("/insights/2")}
          >
            <img
              src={isMobile ? fosteringMobile : bgFosteringImg}
              alt="slideImg"
              className="insightImageBg"
            />

            <div className="insightNavigationData">
              <div className="insightResearch">
                <span>Research</span>
              </div>
              <div className="insightContents">
                <p>
                  Fostering voluntary, nature-based GHG offsets through
                  cost-efficient MRV tools | Part 2
                </p>
                {!isMobile && (
                  <img
                    src={navigator}
                    alt="navigateImg"
                    className="contentsImg"
                  />
                )}
              </div>
            </div>
          </div>

          <div
            className="insightImage"
            id="remote"
            onClick={() => navigate("/insights/5")}
          >
            <img
              src={isMobile ? bgFosteringImgMobile : fostering}
              alt="slideImg"
              className="insightImageBg"
            />

            <div className="insightNavigationData">
              <div className="insightResearch">
                <span>Research</span>
              </div>
              <div className="insightContents organic">
                <p>Remote Sensing and Soil Organic Carbon</p>
                {!isMobile && (
                  <img
                    src={navigator}
                    alt="navigateImg"
                    className="contentsImg"
                  />
                )}
              </div>
            </div>
          </div>

          <div
            className="insightImage"
            id="nature"
            onClick={() => navigate("/insights/6")}
          >
            <img
              src={isMobile ? forestImgMobile : forestImg}
              alt="slideImg"
              className="insightImageBg"
            />

            <div className="insightNavigationData">
              <div className="insightResearch">
                <span>Research</span>
              </div>
              <div className="insightContents nature">
                <p>
                  The role of nature-based solutions in fighting climate change
                </p>
                {!isMobile && (
                  <img
                    className="contentsImg"
                    src={navigator}
                    alt="navigateImg"
                  />
                )}
              </div>
            </div>
          </div>

          <div
            className="insightImage"
            id="openModal"
            onClick={handleVideoClick}
          >
            <img
              src={isMobile ? bgVideoMobile : bgVideo}
              alt="slideImg"
              className="insightImageBg"
            />

            <div className="insightNavigationData">
              <div className="insightResearch">
                <span>Video</span>
              </div>
              <div className="insightContents">
                <p>The Future of Carbon Project Analytics</p>
                {!isMobile && (
                  <img
                    src={navigator}
                    alt="navigateImg"
                    className="contentsImg"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default InsightSection;
