import React, { useState, useEffect } from "react";
import "components/SatelliteResponsive/satelliteResponsive.scss";
import { contentsData } from "constant/contents";

const SatelliteResponsive = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isActiveOpen, setIsActiveOpen] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveIndex((prevIndex) => {
        if (prevIndex === contentsData.length - 1) {
          return 0;
        } else {
          return prevIndex + 1;
        }
      });
    }, 5000);

    return () => clearInterval(timer); // Clear the timer when component unmounts or when activeIndex changes
  }, [activeIndex]); // Run effect when activeIndex changes

  const handleClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    setIsActiveOpen(true);
  };

  return (
    <div className="responsiveCard">
      {contentsData.map((item, index) => (
        <div key={index} className="responsiveCardFull">
          <div
            className={`responsiveCardDetails ${
              activeIndex === index ? "activeClass" : ""
            }`}
          >
            <div
              className={`responsiveCardBefore ${
                activeIndex === index ? "activeClass" : ""
              }`}
              onClick={() => handleClick(index)}
            >
              <div
                className={`responsiveCardTitle ${
                  isActiveOpen ? "activeOpen" : ""
                }`}
              >
                {activeIndex !== index && (
                  <h3 className="heading">{item.title}</h3>
                )}
                {activeIndex === index && (
                  <div className="responsiveLine">
                    <div className="responsiveCardVideo">
                      <video
                        src={item.image}
                        type="video/mp4"
                        muted
                        loop
                        playsInline
                        autoPlay
                        className="video"
                        preload="auto"
                      />
                    </div>
                    <h3>{item.title}</h3>
                    <p>{item.about}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SatelliteResponsive;
