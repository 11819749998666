export const footerItems = [
  {
    title: "PRIVACY POLICY",
    route: "/"
  },
  {
    title: "TERMS OF USE",
    route: "/terms-of-use"
  } /*,
  {
    title: "COOKIE POLICY",
    route: "/cookie-policy"
  },
  {
    title: "SITEMAP",
    route: "/sitemap"
  }*/
];
