import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import "animate.css";

// import slide1 from "assets/images/slider-1.png";
import slide1 from "assets/images/Container-2.png";
import slide2 from "assets/images/slider-2.png";
// import slideImg from "assets/images/Container-3.png";
import slide3 from "assets/images/slider-3.png";
import slide4 from "assets/images/slider-4.png";
// import slide5 from "assets/images/slider-5.png";
import slide5 from "assets/images/Container-1.png";
import slide6 from "assets/images/Container-5.jpg";

import arrowLeft from "assets/icons/arrowLeft.svg";

import "./carousel.scss";
import "swiper/css";
import "swiper/css/pagination";

const Carousel = () => {
  const windowWidth = window.innerWidth;
  const navigate = useNavigate();
  const handleNavigation = (id) => {
    navigate(`/insights/${id}`);
  };

  return (
    <div className="carousels">
      <Swiper
        effect={"coverflow"}
        grabCursor={false}
        centeredSlides={true}
        spaceBetween={windowWidth <= 800 ? 0 : -200}
        loop={false}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5
        }}
        initialSlide={2}
        className="swiperContainer animate__animated animate__fadeInUp"
        modules={[Pagination, Navigation]}
        pagination={{ el: ".swiperPagination", clickable: true }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          disabledClass: "swiper-button-disabled"
        }}
      >
        <SwiperSlide key={slide1} className="slide">
          <div className="overlay" onClick={() => handleNavigation(4)}></div>
          <img className="slideImage" src={slide1} alt="" />
          <p className="slideContent">Rice Methane Emission</p>
        </SwiperSlide>
        <SwiperSlide
          key={slide2}
          className="slide"
          onClick={() => handleNavigation(5)}
        >
          <div className="overlay"></div>
          <img className="slideImage" src={slide2} alt="" />
          <p className="slideContent">Remote Sensing and Soil Organic Carbon</p>
        </SwiperSlide>
        <SwiperSlide
          key={slide6}
          className="slide"
          onClick={() => handleNavigation(7)}
        >
          <div className="overlay"></div>
          <img className="slideImage" src={slide6} alt="" />
          <p className="slideContent">Report: AI for Sustainable Rice</p>
        </SwiperSlide>
        <SwiperSlide
          key={slide3}
          className="slide"
          onClick={() => handleNavigation(1)}
        >
          <div className="overlay"></div>
          <img className="slideImage" src={slide3} alt="" />
          <p className="slideContent">
            {" "}
            Fostering voluntary, nature-based GHG offsets through cost-efficient
            MRV tools | Part 1
          </p>
        </SwiperSlide>
        <SwiperSlide
          key={slide4}
          className="slide"
          onClick={() => handleNavigation(2)}
        >
          <div className="overlay"></div>
          <img className="slideImage" src={slide4} alt="" />
          <p className="slideContent">
            Fostering voluntary, nature-based GHG offsets through cost-efficient
            MRV tools | Part 2
          </p>
        </SwiperSlide>
        <SwiperSlide
          key={slide5}
          className="slide"
          onClick={() => handleNavigation(3)}
        >
          <div className="overlay"></div>
          <img className="slideImage" src={slide5} alt="" />
          <p className="slideContent">Landscape Carbon Modeling</p>
        </SwiperSlide>
        {/* <div className="swiperPagination"></div> */}
        <div className="paginationArrows">
          <img src={arrowLeft} className="swiper-button-prev" alt="" />
          <div className="swiperPagination"></div>
          <img src={arrowLeft} className="swiper-button-next" alt="" />
        </div>
      </Swiper>
    </div>
  );
};

export default Carousel;
