import React, { useEffect, useState } from "react";

const CarouselSlide = ({
  imagePath,
  heading,
  index,
  subHeading,
  activeIndex
}) => {
  const [isActive, setIsActive] = useState(false);

  // Update isActive state based on activeIndex prop changes
  useEffect(() => {
    setIsActive(index === activeIndex);
  }, [index, activeIndex]);

  return (
    <div className={`carouselData ${isActive ? "activeSlide" : ""}`}>
      <img src={imagePath} alt={`Image ${index + 1}`} />
      <h2>
        {heading} <p>{subHeading}</p>
      </h2>
    </div>
  );
};

export default CarouselSlide;
