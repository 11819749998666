import { useRef } from "react";
import { useInView } from "framer-motion";
import "animate.css";
// components
import "components/Concepts/concept.scss";

// import lineImage from "assets/images/home/sideLine.svg";

import { conceptData } from "constant/concepts";

const Concepts = () => {
  const concepts = useRef();
  const conceptsInView = useInView(concepts, { amount: 0.1, once: true });
  const conceptsCardsInView = useInView(concepts, { amount: 0.2, once: true });

  return (
    <section className="concepts" ref={concepts}>
      <div className="container">
        <div className="commonHeading">
          <h1
            className={
              conceptsInView
                ? "animate__animated animate__fadeInUp"
                : "animate__animated animate__fadeOutUp"
            }
          >
            {/* <img src={lineImage} alt="image" /> */}
            What is 4D-MRV?
            {/* <img src={lineImage} alt="image" /> */}
          </h1>
          <p
            className={
              conceptsInView
                ? "animate__animated animate__fadeInUp"
                : "animate__animated animate__fadeOutUp"
            }
          >
            This Is How The 4D-MRV Concept Works In Practice
          </p>
        </div>

        {/*cards*/}
        <div className="conceptsPractice">
          {conceptData.map((concept, index) => (
            <div
              className={`conceptsPracticeGradient ${
                conceptsCardsInView
                  ? "animate__animated animate__fadeInUp"
                  : "animate__animated animate__fadeOutUp"
              }`}
              key={index}
            >
              <div className="conceptsPracticeMeasure">
                <div className="conceptsPracticeIcon">
                  <img src={concept?.image} alt="image" />
                </div>
                <h4>{concept.title}</h4>
                <p>{concept.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Concepts;
