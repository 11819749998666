// images
import amazon from "assets/images/Logos/amazon.png";
import vwcp from "assets/images/Logos/volkswagen.png";
import hartree from "assets/images/Logos/hartree.png";
import cip from "assets/images/Logos/climateIP.png";
import rabobank from "assets/images/Logos/rabobank.png";
import universitycambridge from "assets/images/Logos/cambridge.png";
import verra from "assets/images/Logos/verra.png";
import usaid from "assets/images/Logos/usAid.png";
import maxar from "assets/images/Logos/maxar.png";

export const partnerImages = [
  { name: "AmazonLogo", src: amazon },
  { name: "VwcpLogo", src: vwcp },
  { name: "HartreeLogo", src: hartree },
  { name: "CipLogo", src: cip },
  { name: "RabobankLogo", src: rabobank },
  { name: "UniversitycambridgeLogo", src: universitycambridge },
  { name: "VerraLogo", src: verra },
  { name: "UsaidLogo", src: usaid },
  { name: "MaxarLogo", src: maxar },
  {}
];
