// import React, { useEffect, useRef, useState, useCallback } from "react";
import "animate.css";
import "components/HomeBanner/HomeBanner.scss";
import OurField from "components/OurField/index";
import { useNavigate } from "react-router-dom";

const index = () => {
  const navigate = useNavigate();

  const handleNavItemSelect = () => {
    navigate("/contact-us");
  };

  // const [globeScale, setGlobeScale] = useState(1);
  // const [globeOpacity, setGlobeOpacity] = useState(1);
  // const [pos, setPos] = useState(0);
  // const sectionOne = useRef();
  // const sectionTwo = useRef();
  // const earth = useRef();
  // var earthX = 0;
  // var earthY = 0;
  // var earthHeight = 0;
  // var sectionOneHeight = 0;
  // useEffect(() => {
  //   const earthRect = earth.current.getBoundingClientRect();
  //   const sectionOneDetails = sectionOne.current.getBoundingClientRect();
  //   earthX = earthRect.x;
  //   earthY = earthRect.y;
  //   earthHeight = earthRect.height / 2;
  //   sectionOneHeight = sectionOneDetails.height;
  // });

  // const handleScroll = useCallback(
  //   (e) => {
  //     let value = window.scrollY;
  //     const direction = e.currentTarget;
  //     if (direction.scrollY < 0) {
  //       setGlobeOpacity(1);
  //       setGlobeScale(1);
  //     }
  //     if (pos > direction.scrollY) {
  //       if (window.innerWidth > 768) {
  //         sectionTwo.current.style["clip-path"] = `circle(${value}px at ${
  //           earthX + earthHeight
  //         }px ${earthY + earthHeight}px)`;
  //       } else {
  //         sectionTwo.current.style["clip-path"] = `circle(${value}px at ${
  //           earthX + earthHeight
  //         }px ${earthY + earthHeight}px)`;
  //       }
  //       if (value < sectionOneHeight) {
  //         earth.current.style.height = "";
  //         earth.current.style.width = "";
  //         earth.current.style.marginLeft = "";
  //         sectionTwo.current.style.position = "fixed";
  //         if (value < sectionOneHeight / 6) {
  //           earth.current.style.transition = "all 0s ease";
  //           setGlobeOpacity((prev) => {
  //             const newOpacity = prev + value / 5000;
  //             return Math.min(newOpacity, 1.2);
  //           });
  //           setGlobeScale((prev) => {
  //             const newScale = prev + value / 5000;
  //             return Math.min(newScale, 1.2);
  //           });
  //         }
  //         if (value === 0) {
  //           setGlobeOpacity(1);
  //           setGlobeScale(1);
  //           sectionTwo.current.style["clip-path"] = `circle(0px at ${
  //             earthX + earthHeight
  //           }px ${earthY + earthHeight}px)`;
  //         }
  //       }
  //     } else if (pos < direction.scrollY) {
  //       if (value < sectionOneHeight / 6) {
  //         setGlobeScale((prev) => {
  //           const newOpacity = Math.max(prev + value / 10000, 0);
  //           return Math.min(newOpacity, 1.2);
  //         });
  //       } else if (value > sectionOneHeight / 6) {
  //         earth.current.style.transition = "all 0.5s ease";
  //         setGlobeOpacity((prev) => {
  //           const newOpacity = Math.max(prev - value / 5000, 0);
  //           return Math.min(newOpacity, 1.2);
  //         });
  //         setGlobeScale((prev) => {
  //           const newOpacity = Math.max(prev - value / 5000, 0);
  //           return Math.min(newOpacity, 1.2);
  //         });
  //         if (window.innerWidth > 768) {
  //           sectionTwo.current.style["clip-path"] = `circle(${
  //             value - sectionOneHeight / 6
  //           }px at ${earthX + earthHeight}px ${earthY + earthHeight}px)`;
  //         } else {
  //           sectionTwo.current.style["clip-path"] = `circle(${
  //             value - sectionOneHeight / 6
  //           }px at ${earthX + earthHeight}px ${earthY + earthHeight}px)`;
  //         }
  //         if (value > sectionOneHeight) {
  //           sectionTwo.current.style.position = "fixed";
  //           earth.current.style.height = "1px";
  //           earth.current.style.width = "1px";
  //           if (window.innerWidth > 768) {
  //             earth.current.style.marginLeft = "-25px";
  //           } else if (window.innerWidth < 768) {
  //             earth.current.style.marginLeft = "-10px";
  //           }
  //           earth.current.style.transition = "all 2s ease";
  //           setGlobeOpacity(0);
  //           setGlobeScale(0);
  //           if (value > sectionOneHeight + 500) {
  //             sectionTwo.current.style["clip-path"] = `none`;
  //           }
  //         }
  //       }
  //     }
  //     setPos(window.scrollY);
  //   },
  //   [pos]
  // );

  // useEffect(() => {
  //   setPos(window.scrollY);

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [handleScroll]);

  return (
    // <section className="homeBanner">
    //   <div className="homeBannerOne" ref={sectionOne}>
    //     <div className="homeBannerOneTitle">
    //       <h1>
    //         Unlock the{" "}
    //         <span
    //           ref={earth}
    //           style={{
    //             // height: `${globeSize}px`,
    //             // width: `${globeSize}px`,
    //             opacity: globeOpacity,
    //             transform: `scale(${globeScale})`
    //           }}
    //         ></span>{" "}
    //         <label>full potential</label> of your nature-based project
    //       </h1>
    //       <p className="animate__animated animate__fadeInUp">
    //         Implement sustainable forestry and agriculture initiatives with
    //         confidence, scale and meaningful impact.
    //       </p>
    //     </div>
    //   </div>
    //   <div className="homeBannerTwo" ref={sectionTwo}>
    //     <h1>
    //       <label> Unlock the </label>
    //       <span
    //         ref={earth}
    //         style={{
    //           // height: `${globeSize}px`,
    //           // width: `${globeSize}px`,
    //           opacity: globeOpacity,
    //           transform: `scale(${globeScale})`
    //         }}
    //       >
    //         {" "}
    //       </span>{" "}
    //       full potential of your nature-based project
    //     </h1>
    //     <p>
    //       Implement sustainable forestry and agriculture initiatives with
    //       confidence, scale and meaningful impact.
    //     </p>
    //   </div>
    //   <div className="homeBannerContainer">
    //     <OurField />
    //   </div>
    // </section>
    <>
      <section className="home">
        {/* <div className="homeBannerOne" ref={sectionOne}> */}
        <div className="homeBannerOne">
          <div className="homeBannerOneTitle">
            <h1 className="animate__animated animate__fadeInUp">
              {/* <span
            ref={earth}
            style={{
              // height: `${globeSize}px`,
              // width: `${globeSize}px`,
              opacity: globeOpacity,
              transform: `scale(${globeScale})`
            }}
            ></span>{" "} */}
              <div>Unlock the full potential</div> of your nature-based project
            </h1>
            <p className="animate__animated animate__fadeInUp">
              Implement sustainable forestry and agriculture initiatives with
              confidence, scale and meaningful impact.
            </p>

            <button
              className="commonButton animate__animated animate__fadeInUp"
              onClick={handleNavItemSelect}
            >
              <label className="buttonText">Let’s Get Started</label>
            </button>
          </div>
        </div>
      </section>
      <div className="homeBannerContainer">
        <OurField />
      </div>
    </>
  );
};

export default index;
