import { useEffect, useRef, useState } from "react";

// import lineImage from "assets/images/home/sideLine.svg";

import mantleLabLogo from "assets/images/mantleLabLogos.svg";
import separator from "assets/images/home/separator.svg";
import geoTreeLogo from "assets/images/home/geotree-logo.svg";
import buyer from "assets/images/home/buyer.svg";
import financier from "assets/images/home/financier.svg";
import regulatory from "assets/images/home/regulatory.svg";
import projectDevelopment from "assets/images/home/projectDevelopment.svg";

import "components/CarbonChain/carbonChain.scss";
import { useInView } from "../../../node_modules/framer-motion/";

const CarbonChain = () => {
  const [isMobileView, setIsMobileView] = useState(false);
  const interestedPartner = useRef();

  const interestedPartnerIsInView = useInView(interestedPartner, {
    amount: 0.2,
    once: true
  });

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 992);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="carbon" ref={interestedPartner}>
      <div className="container">
        <div className="commonHeading">
          <h1
            className={
              interestedPartnerIsInView
                ? "animate__animated animate__fadeInUp"
                : "animate__animated animate__fadeOutDown"
            }
          >
            {/* <img src={lineImage} alt="image" /> */}
            Positioning
            {/* <img src={lineImage} alt="image" /> */}
          </h1>
          <p
            className={
              interestedPartnerIsInView
                ? "animate__animated animate__fadeInUp"
                : "animate__animated animate__fadeOutDown"
            }
          >
            Enabling Critical Decisions For The Entire Carbon Chain
          </p>
        </div>

        {isMobileView ? (
          <>
            <div className="carbonResponsive">
              <div
                className={`carbonLifeCycleMobile ${
                  interestedPartnerIsInView
                    ? "animate__animated animate__fadeInUp"
                    : "animate__animated animate__fadeOutDown"
                }`}
              >
                <div className="logo">
                  <img src={buyer} alt="buyImg" />
                  <span>Buyers</span>
                </div>

                <ul>
                  <li>Screen incoming proposals</li>
                  <li>Feasibility</li>
                  <li>Financial and carbon modelling</li>
                  <li>Ongoing performance monitoring</li>
                </ul>
              </div>

              <div
                className={`carbonLifeCycleMobile ${
                  interestedPartnerIsInView
                    ? "animate__animated animate__fadeInUp"
                    : "animate__animated animate__fadeOutDown"
                }`}
              >
                <div className="logo">
                  <img src={regulatory} alt="buyImg" />
                  <span>Regulatory</span>
                </div>

                <ul>
                  <li>Project audits</li>
                  <li>Compliance checks</li>
                </ul>
              </div>

              <div
                className={`carbonLifeCycleMobile ${
                  interestedPartnerIsInView
                    ? "animate__animated animate__fadeInUp"
                    : "animate__animated animate__fadeOutDown"
                }`}
              >
                <div className="logo">
                  <img src={financier} alt="buyImg" />
                  <span>Financier/Investors</span>
                </div>

                <ul>
                  <li>Select project locations</li>
                  <li>Pre feasibility</li>
                  <li>Screen incoming proposals</li>
                  <li>Feasibility</li>
                  <li>Financial and carbon modelling</li>
                  <li>Ongoing performance monitoring</li>
                </ul>
              </div>

              <div
                className={`carbonLifeCycleMobile ${
                  interestedPartnerIsInView
                    ? "animate__animated animate__fadeInUp"
                    : "animate__animated animate__fadeOutDown"
                }`}
              >
                <div className="logo">
                  <img src={projectDevelopment} alt="buyImg" />
                  <span>Project Development</span>
                </div>

                <ul>
                  <li>Select project locations</li>
                  <li>Pre feasibility</li>
                  <li>Feasibility</li>
                  <li>Project financing</li>
                  <li>PDD writing and submission</li>
                  <li>Project registration with regulators & standards</li>
                  <li>Ongoing performance monitoring</li>
                </ul>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="carbonLifeCycle">
              <div className="carbonLifeCycleLogo">
                <img src={mantleLabLogo} alt="logo" />
                <img src={separator} alt="separator" />
                <img src={geoTreeLogo} alt="geoTreeLogo" />
              </div>

              <div className="carbonLifeCycleCard">
                <div className="carbonLifeCycleCardBuy">
                  <div className="logo">
                    <img src={buyer} alt="buyImg" />
                    <span>Buyers</span>
                  </div>

                  <ul>
                    <li>Screen incoming proposals</li>
                    <li>Feasibility</li>
                    <li>Financial and carbon modelling</li>
                    <li>Ongoing performance monitoring</li>
                  </ul>
                </div>
              </div>

              <div className="carbonLifeCycleCard">
                <div className="carbonLifeCycleCardBuy">
                  <div className="logo">
                    <img src={regulatory} alt="buyImg" />
                    <span>Regulatory</span>
                  </div>

                  <ul>
                    <li>Project audits</li>
                    <li>Compliance checks</li>
                  </ul>
                </div>
              </div>

              <div className="carbonLifeCycleCard newCard">
                <div className="carbonLifeCycleCardBuy">
                  <div className="logo">
                    <img src={financier} alt="buyImg" />
                    <span>Financier/Investors</span>
                  </div>

                  <ul>
                    <li>Select project locations</li>
                    <li>Pre feasibility</li>
                    <li>Screen incoming proposals</li>
                    <li>Feasibility</li>
                    <li>Financial and carbon modelling</li>
                    <li>Ongoing performance monitoring</li>
                  </ul>
                </div>
              </div>

              <div className="carbonLifeCycleCard newCard">
                <div className="carbonLifeCycleCardBuy">
                  <div className="logo">
                    <img src={projectDevelopment} alt="buyImg" />
                    <span>Project Development</span>
                  </div>

                  <ul>
                    <li>Select project locations</li>
                    <li>Pre feasibility</li>
                    <li>Feasibility</li>
                    <li>Project financing</li>
                    <li>PDD writing and submission</li>
                    <li>Project registration with regulators & standards</li>
                    <li>Ongoing performance monitoring</li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default CarbonChain;
