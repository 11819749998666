import React, { useEffect, useRef, useState } from "react";

// import lineImage from "assets/images/home/sideLine.svg";
import bgImage from "assets/images/home/reponsiveVideoImage.png";
import video from "assets/videos/homePageVideo.mp4";

import "components/MRVLifeCycle/mrvLifeCycle.scss";

const MRVLifeCycle = () => {
  const videoRef = useRef(null);
  const observer = useRef(null);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3
    };

    /* eslint-disable */
    observer.current = new IntersectionObserver(handleIntersection, options);
    /* eslint-enable */

    if (videoRef.current && !isMobileView) {
      observer.current.observe(videoRef.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.intersectionRatio > 0.3) {
        // Play the video
        videoRef.current.src = video;
        videoRef.current?.play();

        // Disconnect the observer after playing the video once
        // if (observer.current) {
        //   observer.current.disconnect();
        // }
      } else {
        // Pause the video if it's not intersecting
        videoRef.current?.pause();
      }
    });
  };

  // const handleVideoEnd = () => {
  //   videoRef.current.currentTime = videoRef.current.duration - 2;
  //   videoRef.current?.pause();
  // };

  useEffect(() => {
    videoRef.current.addEventListener("timeupdate", function () {
      if (videoRef.current?.currentTime > 12.8) {
        videoRef.current?.pause();
      }
    });
  }, []);

  return (
    <section className="mrvSoln">
      <div className="container">
        <div className="commonHeading">
          <h1>
            {/* <img src={lineImage} alt="image" /> */}
            Our 4D-MRV Solutions
            {/* <img src={lineImage} alt="image" /> */}
          </h1>
          <p>
            Covering The Entire Lifecycle of Nature-based Carbon Projects For
            All Stakeholders.
          </p>
        </div>
        <div className="mrvSolnVideo">
          {isMobileView ? (
            <img src={bgImage} alt="image" />
          ) : (
            <video
              ref={videoRef}
              type="video/mp4"
              controls={false}
              muted
              // loop
              playsInline
              className="mrvSolnVideoElement"
              // preload="auto"
              // onEnded={handleVideoEnd}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default MRVLifeCycle;
