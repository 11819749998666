import React from "react";
import "./enquirysent.scss";
import closeIcon from "assets/icons/windowClose.svg";
import Success from "assets/icons/Success.svg";
import Error from "assets/icons/Error.svg";

const enquiryResult = ({ status, errorMessage }) => {
  if (status == true) {
    return (
      <>
        <img className="enquirySentContentsuccess" src={Success} alt="" />
        <h3 className="enquirySentContenttitle">Thank you </h3>
        <p className="enquirySentContentsubtitle">
          We have received your enquiry.
        </p>
      </>
    );
  } else {
    return (
      <>
        {<img className="enquirySentContenterror" src={Error} alt="" />}
        <h3 className="enquirySentContenttitle">Unable to submit the form</h3>
        <p className="enquirySentContentsubtitle">{errorMessage}</p>
      </>
    );
  }
};

function EnquirySent({ open, close, status, errorMessage }) {
  console.log("OPEN: ", open);
  console.log("STATUS: ", status);
  console.log("MESSAGE: ", errorMessage);
  return (
    open && (
      <div className="enquirySent" onClick={close}>
        <div className="enquirySentContainer">
          <div className="enquirySentContent">
            <img
              className="enquirySentContentclose"
              src={closeIcon}
              alt="close.svg"
              onClick={close}
            />
            <>
              {/*<p>{status}</p><p>{errorMessage}</p>*/}
              {enquiryResult({ status, errorMessage })}
            </>
          </div>
        </div>
      </div>
    )
  );
}

export default EnquirySent;
