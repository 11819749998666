import NewsBannerIcon from "assets/images/news/newsBannerIcon.svg";

export const newsBannerData = [
  {
    icon: NewsBannerIcon,
    date: "17 NOVEMBER 2023",
    heading:
      "Verra Initiates Remote Sensing Analysis Pilot with Mantle Labs for Review of Small-Scale Methodology Rice Projects",
    para: "Initiative sampled five projects using AMS-III.AU to check project area, baseline and project Adjusted Water Management practices...",
    button: "Read More",
    link: "https://verra.org/verra-initiates-remote-sensing-analysis-pilot-with-mantle-labs-for-review-of-small-scale-methodology-rice-projects/"
  },
  {
    icon: NewsBannerIcon,
    date: "2 APRIL 2024",
    heading:
      "Japan's Faeger uses satellite tool to monitor rice methane schemes",
    para: "Quantum Commodity Intelligence – Japanese carbon project developer Faeger has agreed to utilise remote sensing technology tools...",
    button: "Read More",
    link: "https://www.qcintel.com/carbon/article/japan-s-faeger-uses-satellite-tool-to-monitor-rice-methane-schemes-23065.html"
  }
];
