import { useRef } from "react";
import { useInView } from "framer-motion";
import "animate.css";

import earth from "assets/images/earth.svg";

import "components/ProductContent/productcontent.scss";

const ProductContent = () => {
  const productContent = useRef();
  const productContentInView = useInView(productContent, {
    amount: 0.2,
    once: true
  });
  return (
    <section className={`productContent`} ref={productContent}>
      <div
        className={`container productContentEarth ${
          productContentInView
            ? "animate__animated animate__fadeInUp"
            : "animate__animated animate__fadeOutUp"
        }`}
      >
        <div className="productContentData">
          <h2>
            Deep earth observation capabilities spanning agriculture, forestry
            and coastal wetlands
          </h2>
          <p>
            We aim to set the standard when it comes to the monitoring,
            reporting and verification of nature based solutions. Our team is
            uniquely positioned given its depth of academic research which
            covers the application of earth observation across all the main
            natural carbon sinks.
          </p>
        </div>

        <div className="productContentImage">
          <img src={earth} alt="deepEarth" />
        </div>
      </div>
    </section>
  );
};

export default ProductContent;
