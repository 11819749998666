import { useRef, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { newsBannerData } from "constant/newsBannerData";

import { useNavigate } from "react-router-dom";
import PreArrow from "assets/images/news/preArrow.svg";
import AfterArrow from "assets/images/news/afterArrow.svg";

import "components/NewsBanner/newsBanner.scss";
import "@splidejs/splide/dist/css/splide.min.css";
import { Link } from "react-router-dom";

const NewsBanner = () => {
  const navigate = useNavigate();
  const handleNavItemSelect = () => {
    navigate("");
  };

  const splideRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const splideOptions = {
    start: 0,
    perPage: 1,
    rewind: false,
    pagination: false,
    arrows: false,
    breakpoints: {
      1440: {
        // perPage: 4
      }
    }
  };

  const goToNextSlide = () => {
    if (splideRef.current) {
      splideRef.current.go("+1");
    }
  };

  const goToPrevSlide = () => {
    if (splideRef.current) {
      splideRef.current.go("-1");
    }
  };

  const handleMove = (_, newIndex) => {
    setCurrentPage(newIndex + 1);
  };

  return (
    <section className="newsBanner">
      <Splide
        options={splideOptions}
        onFirstMount={() => setCurrentPage(1)}
        onMove={handleMove}
        ref={splideRef}
      >
        {newsBannerData.map((data, index) => (
          <SplideSlide key={index}>
            <div className={`container  ${"bannerContainer" + index}`}>
              <div className="newsBannerContents">
                <div className="newsBannerContentsButton">
                  <img src={data.icon} alt="NewsBannerIcon" />
                  <span>{data.date}</span>
                </div>
                <h1>{data.heading}</h1>
                <p>{data.para}</p>
                <Link to={data.link} target="_blank">
                  <button
                    className="commonButton animate__animated animate__fadeInUp"
                    onClick={handleNavItemSelect}
                  >
                    <label className="buttonText">{data.button}</label>
                  </button>
                </Link>
              </div>

              <div className="counter">
                <span>
                  <img
                    src={PreArrow}
                    alt="prevImg"
                    className={`counterImg ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                    onClick={goToPrevSlide}
                  />
                  <span
                    className={`counterNumber ${
                      currentPage === 1 || currentPage === 2 ? "activeNews" : ""
                    }`}
                  >
                    <span className="showingText">Showing</span>
                    <span
                      className={`${
                        currentPage === 1 || currentPage === 2
                          ? "activePage"
                          : "inactivePage"
                      }`}
                    >
                      {Math.ceil(currentPage)}
                    </span>
                    / {newsBannerData?.length}
                  </span>

                  <img
                    src={AfterArrow}
                    alt="nextImg"
                    className={`counterImg ${
                      currentPage === newsBannerData?.length ? "disabled" : ""
                    }`}
                    onClick={goToNextSlide}
                  />
                </span>
              </div>

              <div className="counterResponsive counterResponsiveCustomer ">
                <span>
                  <img
                    src={PreArrow}
                    alt="prevImg"
                    className={`counterImg ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                    onClick={goToPrevSlide}
                  />
                  <span
                    className={`counterNumber ${
                      currentPage === 1 || currentPage === 2 ? "activeNews" : ""
                    }`}
                  >
                    <span className="showingText">Showing</span>
                    <span
                      className={`${
                        currentPage === 1 || currentPage === 2
                          ? "activePage"
                          : "inactivePage"
                      }`}
                    >
                      {Math.ceil(currentPage)}
                    </span>
                    / {newsBannerData?.length}
                  </span>
                  <img
                    src={AfterArrow}
                    alt="nextImg"
                    className={`counterImg ${
                      currentPage === newsBannerData?.length ? "disabled" : ""
                    }`}
                    onClick={goToNextSlide}
                  />
                </span>
              </div>
            </div>
          </SplideSlide>
        ))}
      </Splide>
    </section>
  );
};

export default NewsBanner;
