import "components/InsightVideoCard/insightVideoCard.scss";
// import { useRef } from "react";

const InsightVideoCard = ({ data, action, videoRef }) => {
  // const videoRef = useRef();

  return (
    <div className="insightVideoCard" id={data?.idName}>
      <div className="insightVideoCardImg">
        <img src={data?.image} alt="" />
      </div>
      <div className="insightVideoCardContent">
        <p>{data?.description}</p>
        <button onClick={() => action(data?.id)} ref={videoRef}>
          <img src={data?.buttonIcon} alt="" />
          {data?.buttonText}
        </button>
      </div>
    </div>
  );
};

export default InsightVideoCard;
